.generic-content {
	background-color: $color-white;
	color: $color-black;
	padding-bottom: 1px;
	max-width: 1050px;
	margin: 0 auto;
	z-index: 10;
	position: relative;

	@include bp(lg) {
		width: 95%;
	}

	&:after {
		content: '';
		position: absolute;
		background-image: url("img/bottom-dec.svg");
		background-size: cover;
		width: 300%;
		height: 397px;
		bottom: -35px;
		left: -100%;
	}

	@include bp(lg) {
		padding-top: 1px;
	}

	.container {
		z-index: 1;

		@include bp(lg) {
			&:before {
				content: url('img/left-border-top-long.svg');
				left: -42px;
				position: absolute;
				top: -70px;
			}
		}

		@include bp(lg) {
			max-width: 965px;
			width: 92%;
		}
	}
}