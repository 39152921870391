.faqs {
	padding-top: 95px;
	margin-top: -20px;

	@include bp(lg) {
		text-align: center;
	}

	.container {
		@include bp(lg) {
			max-width: 968px;
		}
	}

	&--art {
		width: 130vw;
		max-width: 999999px;
		margin-left: -16vw;
	}

	&--mark {
		max-width: 240px;
		margin-left: 30px;
		margin-top: -25px;

		@include bp(lg) {
			max-width: 400px;
			margin-top: 0;
			margin-left: 120px;
			margin-bottom: 10px;
		}
	}

	&--intro {
		text-align: center;
		padding-bottom: 10px;

		@include bp(lg) {
			padding-bottom: 22px;
		}

		h2 {
			color: $color-black;
			text-transform: uppercase;
			margin-top: 0;

			@include bp(lg) {
				font-size: 42px;
				letter-spacing: 0.5px;
			}
		}
	}

	&--list {
		@include bp(lg) {
			max-width: 1170px;
			margin: auto;
		}
	}
}