.family {
	padding-top: 0;
	position: relative;
	background-color: $color-white;
	z-index: 11;

	&--inner {
		padding-top: 35px;
		position: relative;
		z-index: 10;
		padding-bottom: 40px;

		@include bp(lg) {
			padding-bottom: 130px;
			padding-top: 0;
		}

		.container {
			z-index: 20;
			position: relative;

			@include bp(lg) {
				width: 95%;
			}
		}
	}

	.feature-smudge {
		@include bp(lg) {
			margin-left: -10px;
		}
	}

	&--intro {
		@include bp(lg) {
			margin-bottom: 15px;
		}

		.col {
			padding-bottom: 13px;
		}

		h2 {
			color: $color-black;
			text-transform: uppercase;
			text-align: center;
			margin-top: 5px;

			@include bp(lg) {
				text-align: left;
				font-size: 42px;
				padding-left: 20px;
				margin-top: 0;
			}
		}
	}

	&--mark {
		@include bp(lg) {
			width: 345px;
		}
	}

	&--list {
		@include bp(lg) {
			//padding-left: 160px;
			//padding-right: 160px;
			max-width: 1050px;
			width: 90%;
			margin: auto;
		}

		> .col {
			margin-bottom: 5px;

			@include bp(lg) {
				padding-left: 30px;
				padding-right: 30px;
			}

			&:last-child {
				margin-bottom: 0;

			}
		}
	}

	&--logo {
		padding: 30px 30px 0 30px;
		position: relative;
		height: 124px;
		display: flex;
		justify-content: center;
		align-items: center;

		&:before {
			content: url('img/lef-border-small.svg');
			left: 0;
			position: absolute;
			top: 0;
		}
	}

	&--excerpt {
		font-size: 11px;
		line-height: 1.8;
		margin-top: 5px;
		margin-bottom: 20px;

		@include bp(lg) {
			font-size: 16px;
		}

		.read-more {
			text-transform: uppercase;
			color: $color-black;
		}

		.read-more-boundary {
			color: $color-black;
		}

		&:last-of-type {
			@include bp(lg) {
				margin-bottom: 35px;
			}
		}
	}

	&--content {
		margin-bottom: 40px;

		@include bp(lg) {
			padding: 0 25px 10px 25px;
		}

		h3 {
			color: $color-black;
			margin-top: 10px;
			margin-bottom: 5px;

			@include bp(lg) {
				font-size: 24px;
				margin-top: 25px;
				margin-bottom: 15px;
			}
		}
		.btn {
			width: auto;
			display: inline-block;
			padding: 0 25px;

			&:visited {
				color: $color-black;

				&:hover {
					color: $color-white;
				}
			}

			@include bp(lg) {
				line-height: 60px;
				padding: 0 30px;
			}
		}
	}
}