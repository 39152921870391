.image-panel {
	text-align: center;
	padding: 15px 0 60px 0;
	position: relative;
	z-index: 10;
	overflow: hidden;

	@include bp(lg) {
		padding: 15px 0 170px 0;
	}

	&:before {
		content: '';
		position: absolute;
		background-image: url("img/bottom-dec.svg");
		background-size: cover;
		width: 100%;
		height: 397px;
		top: -185px;
		left: 0;
		transform: rotate(180deg);
	}

	&:after {
		content: '';
		position: absolute;
		background-image: url("img/bottom-dec.svg");
		background-size: cover;
		width: 100%;
		height: 397px;
		bottom: -215px;
		left: 0;
		z-index: -1;
	}

	@include bp(lg) {
		padding-top: 40px;
	}

	&--mark {
		max-width: 220px;
		margin: auto;

		@include bp(lg) {
			max-width: 400px;
		}
	}

	.feature-text {
		display: none;

		@include bp(lg) {
			display: block;
			position: absolute;
			transform: rotate(90deg);
			top: 170px;
			left: -220px;
			transform-origin: top right;
			z-index: 30;
		}
	}

	&--image {
		position: relative;

		@include bp(lg) {
			max-width: 1250px;
			margin: auto;
			position: relative;
		}

		img {
			width: 100%;
			height: auto;
			object-fit: cover;
			margin-bottom: 18px;

			@include bp(lg) {
				height: 625px;
				margin-bottom: 40px;
			}
		}
	}

	h2 {
		color: $color-black;
		margin-top: -5px;

		@include bp(lg) {
			margin-top: 10px;
			margin-bottom: 40px;
		}
	}

	.btn {
		width: auto;
		padding: 0 54px;
	}
}