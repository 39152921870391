.instagram {
	border-bottom: 6px solid $color-gold-dark;
	position: relative;
	z-index: 30;

	@include bp(lg) {
		border-bottom: 10px solid $color-gold-dark;
	}

	&--intro {
		text-align: center;

		img {
			max-width: 240px;
			margin-left: 30px;

			@include bp(lg) {
				max-width: 500px;
				margin-left: 135px;
			}
		}
		h2 {
			color: $color-black;
			text-transform: uppercase;
			margin-top: -5px;
			margin-bottom: 3px;

			@include bp(lg) {
				font-size: 40px;
				letter-spacing: 2px;
			}
		}
		a.h3 {
			color: $color-black;
			text-transform: uppercase;
			text-decoration: none;
			font-size: 18px;
			display: block;
			margin-bottom: 30px;

			@include bp(lg) {
				font-size: 24px;
				margin-bottom: 55px;
			}
		}
	}
}

.template-about {
	+ .instagram {
		padding-top: 0;
	}
}