.image-content-panel {
	padding-top: 30px;
	position: relative;
	z-index: 1;

	@include bp(lg) {
		padding-top: 130px;
		position: relative;
		z-index: 9;
		padding-bottom: 390px;
	}

	&:after {
		content: '';
		position: absolute;
		background-image: url("img/bottom-dec.svg");
		background-size: cover;
		width: 100%;
		height: 397px;
		bottom: 0;
		left: 0;
		z-index: -1;
	}

	.feature-text {
		position: absolute;
		transform: rotate(90deg);
		top: 90%;
		right: 20px;
		transform-origin: top right;

		@include bp(lg) {
			top: 0;
			left: 0;
			right: auto;
			-webkit-transform-origin: top right;
			transform-origin: top left;
			letter-spacing: 2px;
			margin-left: 180px;
			margin-top: -90px;
		}
	}

	.feature-smudge {
		//margin-left: -15px;
		margin-top: -60px;

		@include bp(lg) {
			margin-top: 0;
		}
	}

	.columns {
		> .col {
			width: 100%;
			position: relative;

			> img {
				position: absolute;
				top: -94px;
				right: -34px;

				&.desktop-only {
					@include bp(lg) {
						top: -135px;
						right: auto;
						left: 34px;
					}
				}
			}
		}
	}

	.container {
		position: relative;

		@include bp(lg) {

		}
	}

	&--item {
		position: relative;

		svg {
			&.mobile-only {
				position: absolute;
				right: -21px;
				top: -90px;
			}
		}
	}

	&--image {
		height: 215px;
		overflow: hidden;

		@include bp(lg) {
			height: auto;
		}

		@include bp(xlg) {
			padding-left: 118px;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&--content {
		@include bp(lg) {
			margin-top: 62px;
			margin-left: -118px;
		}

		h2 {
			margin-top: -5px;
			line-height: 1.4;
			color: $color-black;

			@include bp(lg) {
				padding-left: 110px;
			}
		}

		p {
			font-size: 12px;
			margin-bottom: 18px;

			@include bp(lg) {
				font-size: 16px;
				margin-bottom: 28px;
			}
		}

		.btn {
			@include bp(lg) {
				width: auto;
			}
		}

		.read-more-boundary {
			a {
				font-family: $font--poppins-medium;
				color: $color-gold;
				text-transform: uppercase;
			}

			+ p {
				margin-top: 30px;

				@include bp(lg) {
					margin-top: 55px;
					margin-bottom: 0;
				}
			}
		}

		&-inner {
			background-color: $color-blue;
			padding: 2px 15px;
			//color: $color-black;

			@include bp(lg) {
				padding: 50px 72px;
			}
		}

		&-drip {
			text-align: right;

			svg {
				@include bp(lg) {
					margin-right: 75px;
					margin-left: auto;
				}
			}
		}

		&-badge {
			@include bp(lg) {
				width: 462px;
				height: 590px;
				position: absolute;
				bottom: -411px;
				right: 386px;
				z-index: -1;
			}
		}
	}

	h3 {
		@include bp(lg) {
			margin-bottom: 26px;
		}
	}

	&.art-of-feasting {
		padding-top: 0;
		z-index: 10;

		@include bp(lg) {
			padding-bottom: 390px;
			padding-top: 120px;
		}

		.columns {
			flex-wrap: nowrap;

			.col {
				> svg {
					top: 46px;
					right: -34px;
				}
			}
		}
		.image-content-panel--item {
			h2 {
				margin-top: -3px;
				color: $color-black;

				@include bp(lg) {
					padding-left: 110px;
					margin-right: 180px;
				}
			}
		}
		.image-content-panel--content {
			margin-top: 0;

			&-inner {
				padding-top: 8px;

				@include bp(lg) {
					padding-top: 50px;
					position: relative;
				}
			}
		}
	}
	&.our-second-site {
		padding-top: 0;
		text-align: center;
		margin-top: -10px;
		z-index: 10;
		//background-color: $color-white;

		@include bp(lg) {
			padding-top: 85px;
			padding-bottom: 85px;
		}

		&:after {
			bottom: -430px;
			transform: scale(-1);
			z-index: -1;
			height: 100%;
		}

		&:before {
			content: '';
			position: absolute;
			background-image: url("img/bottom-dec.svg");
			background-size: cover;
			width: 100%;
			height: 100%;
			left: 0;
			top: -255px;
		}

		.feature-smudge {
			margin-top: 0;
			margin-left: auto;
			margin-right: auto;

			@include bp(lg) {
				margin-left: -15px;
			}
		}

		.container {
			@include bp(lg) {
				position: static;
				z-index: 10;
			}
		}

		.columns {
			position: static;

			@include bp(lg) {
				flex-wrap: nowrap;
			}

			> .col {
				@include bp(lg) {
					position: static;
				}

				&:last-child {
					@include bp(lg) {
						flex: 0 1 72%;
						position: relative;
						z-index: 10;
						text-align: left;
					}
				}
			}
		}

		h2 {
			color: $color-black;
			margin-top: -10px;

			@include bp(lg) {
				padding-left: 110px;
			}

		}

		.image-content-panel--image {
			height: 265px;

			@include bp(lg) {
				width: 47.75%;
				height: 100%;
				position: absolute;
				left: 0;
				top: 0;
				padding-left: 0;
				//padding-bottom: 110px;
				//padding-top: 45px;
				overflow: visible;
			}
		}

		.image-content-panel--content {
			@include bp(lg) {
				position: relative;
				z-index: 10;
				margin: 0;
			}

			&-inner {
				padding: 5px 15px;
				text-align: left;

				@include bp(lg) {
					padding: 50px 70px;
				}
			}

			p {
				margin-bottom: 15px;
			}
		}

		&--dining {
			margin-bottom: 60px;

			@include bp(lg) {
				margin-bottom: 125px;
			}

			&:before,
			&:after {
				content: none;
			}

			&:nth-child(odd) {
				.image-content-panel--image {
					left: auto;
					right: 0;
				}

				.image-content-panel--item {
					flex-direction: row-reverse;
				}

				h2 {
					padding-left: 0;

					@include bp(lg) {
						padding-right: 110px;
						text-align: right;
					}
				}

				.feature-smudge {
					margin-left: auto;
					margin-right: auto;

					@include bp(lg) {
						margin-right: -170px;
					}
				}
			}

			&:last-child {
				@include bp(lg) {
					margin-bottom: 55px;
				}
			}
		}
	}
}