.testimonials {
    @include bp(lg) {
        padding-top: 0;
    }

    &--inner {
        position: relative;
        color: $color-white;

        @include bp(lg) {
            //padding-top: 159px;
            padding-bottom: 93px;
        }
    }

    .feature-text {
        display: none;

        @include bp(lg) {
            display: block;
            position: absolute;
            top: -110px;
            left: -20px;
        }
    }

    .container {
        position: relative;
        z-index: 10;

        @include bp(lg) {
            display: flex;
            align-items: flex-start;
            width: 95%;

            &:before {
                content: url('img/left-border-long.svg');
                left: -11px;
                position: absolute;
                top: -31px;
            }
        }
    }

    h2 {
        color: $color-black;
        margin: -5px 0 10px 0;
        text-transform: uppercase;
        padding: 0 7px;

        @include bp(lg) {
            letter-spacing: 1.5px;
            margin: -5px 0 80px 0;
            font-size: calcFluidFontSize(30px, 40px, 1024px, 1920px);
            padding: 0 25% 0 0;
        }
    }

    img {
        &.desktop-only {
            @include bp(lg) {
                //flex: 0 1 50%;
                width: 50%;
                margin-right: 58px;
            }
        }
    }

    &--content {
        @include bp(lg) {
            width: calc(50% - 58px);
        }
    }

    &--mark {
        @include bp(lg) {
            margin-left: -30px;
        }
    }

    .slick {
        @include bp(lg) {
            margin-left: -299px;
            //margin-right: -40px;

            &:after {
                content: url('img/left-border-long.svg');
                right: 52px;
                position: absolute;
                top: -120px;
                transform: rotate(90deg);
            }
        }
    }

    .slide {
        @include bp(lg) {
            padding-right: 0px;
        }

        &--indicator {
            width: 70px;
            height: 70px;
            background-color: $color-red;
            display: inline-flex;
            color: white;
            font-size: 45px;
            justify-content: center;
            align-items: center;
            border-radius: 20px 0 20px 0;
            margin-bottom: 30px;
        }

        &--inner {
            padding: 12px 12px 20px 12px;
            background-color: $color-sky;
            margin-top: 108px;
            color: $color-black;

            @include bp(lg) {
                margin-top: 0;
                padding: 50px 50px 45px 50px;
            }

            img {
                margin-top: -112px;

                @include bp(lg) {
                    display: none;
                }
            }
        }

        &--drip {
            visibility: hidden;
            text-align: center;

            svg {
                margin-right: -10px;
                margin-top: -5px;

                @include bp(lg) {
                    width: 222px;
                    height: 125px;
                    margin-right: -132px;
                }
            }
        }

        h2 {
            @include bp(lg) {
                font-size: 24px;
                font-weight: normal;
                margin-bottom: 27px;
            }
        }

        h3 {
            font-size: 18px;
            line-height: 1.35;
            margin-top: 20px;
            padding: 0 3px;

            @include bp(lg) {
                font-size: calcFluidFontSize(30px, 36px, 1024px, 1920px);
                padding: 0;
                margin: 0;
                line-height: 1.25;
            }
        }

        p {
            padding: 0 4px;
            font-size: 12px;

            @include bp(lg) {
                font-size: 19px;
                letter-spacing: 0.25px;
                margin: 30px 0 0 0;
            }
        }

        .read-more {
            color: white;
            text-decoration: none;
        }
    }

    .next-arrow,
    .prev-arrow {
        width: 50px;
        height: 50px;
        background-color: $color-gold;
        border: 2px solid $color-gold;
        border-radius: 0;
        transform: none;
        cursor: pointer;
        position: absolute;
        top: auto;
        right: 0;
        bottom: 0;
        z-index: 30;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.5s;
        content: 'data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="15.943" height="27.643" viewBox="0 0 15.943 27.643"><path d="M-1827.615-8036.021l12.76,12.761-12.76,12.761" transform="translate(1828.675 8037.082)" fill="none" stroke="#fff" stroke-width="3"/></svg>';

        @include bp(sm) {
            bottom: 0;
        }

        @include bp(lg) {
            width: 66px;
            height: 66px;
            bottom: 32px;
            right: 20px;
        }

        &:hover {
            background-color: $color-green;
            border: 2px solid $color-green;
        }

        svg {
            position: relative;
            right: -2px;
            width: 11px;
            height: 19px;

            @include bp(lg) {
                width: 16px;
                height: 28px;
            }

            path {
                @include bp(lg) {
                    stroke: $color-black;
                }
            }
        }

        &.slick-disabled {
            opacity: 0.75;
        }
    }

    .prev-arrow {
        left: 0;

        @include bp(lg) {
            left: auto;
            right: 112px;
            bottom: 32px;
        }

        svg {
            position: relative;
            right: 0;
            left: -2px;
            transform: rotate(180deg);
        }
    }

    .slick-dots {
        display: flex;
        padding: 0;
        margin: 0;
        height: 50px;
        padding-left: 60px;
        padding-right: 60px;
        justify-content: center;
        align-items: center;
        margin-top: -12px;

        @include bp(lg) {
            height: 66px;
            padding-left: 0;
            justify-content: flex-start;
            align-items: center;
            margin-top: -122px;
        }

        li {
            list-style: none;
            margin: 0 7.5px;
            width: 10px;
            height: 10px;
            overflow: hidden;

            @include bp(lg) {
                width: 17px;
                height: 17px;
                margin: 0 17px 0 0;
            }

            &.slick-active {
                button {
                    background-color: $color-gold;
                    opacity: 1;
                }
            }
        }

        button {
            width: 10px;
            height: 10px;
            border-radius: 0;
            border: 0;
            font-size: 0;
            display: block;
            opacity: 0.4;
            cursor: pointer;
            background-color: $color-beige;

            @include bp(lg) {
                width: 17px;
                height: 17px;
            }
        }
    }
}