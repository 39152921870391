.icon-cards {
	text-align: center;

	&--icon {
		min-height: 86px;
	}

	h2 {
		margin-bottom: 30px;

		@include bp(lg) {
			margin-bottom: 78px;
		}
	}

	h3 {
		@include bp(lg) {
			margin-top: 17px;
			margin-bottom: 20px;
		}
	}

	p {
		line-height: 1.5;

		@include bp(lg) {
			padding: 0 20px;
		}
	}

	&--subpage {
		background-color: $color-grey;

		@include bp(lg) {
			margin-top: 85px;
			padding-top: 85px;
		}

		h3 {
			@include bp(lg) {
				font-size: 20px;
				margin-bottom: 10px;
			}
		}
	}

	&--alternate {
		padding-top: 0;

		.container {
			border-top: 2px solid $color-grey;

			@include bp(lg) {
				padding-top: 105px;
			}
		}

		@include bp(lg) {
			margin-top: 35px;
		}
	}
}