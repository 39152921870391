.container {
    width: 100%;
    padding: 0 15px;
    position: relative;

    &--padded {
        padding: 0 20px;
    }

    @include bp(lg) {
        padding: 0 20px;
        max-width: 1000px;
        margin: 0 auto;
    }
    @include bp(lg) {
        padding: 0 20px;
        max-width: 1445px;
        margin: 0 auto;
    }

    &--inset {
        @include bp(lg) {
            max-width: 1032px;
        }
    }
}
