.our-story {

	@include bp(lg) {
		padding-top: 145px;
	}

	&--intro {
		margin-bottom: 30px;

		@include bp(lg) {
			margin-bottom: 0;
		}

		h2 {
			margin-top: 0;
		}
	}

	&--image {
		height: 250px;
		border-top-left-radius: 40px;
		border-bottom-right-radius: 40px;
		overflow: hidden;

		@include bp(lg) {
			height: 630px;
			margin-bottom: 20px;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&--caption {

	}

	&--content {
		@include bp(lg) {
			height: 460px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}

		p {
			line-height: 1.5;
			margin-bottom: 10px;
		}
	}

	&--timeline {
		position: relative;

		@include bp(lg) {
			padding-top: 38px;
			padding-left: 18px;
		}

		&-list {
			padding-left: 40px;

			@include bp(lg) {
				padding-left: 0;
			}
		}

		&-item {
			border-left: 3px solid #DFE1E5;
			padding-bottom: 50px;
			position: relative;

			h3 {
				margin-top: 3px;
				margin-bottom: 24px;
				color: $color-red;
			}

			&-inner {
				overflow: hidden;
				padding-left: 64px;

				@include bp(lg) {
					padding-left: 98px;
				}
			}

			&:last-of-type {
				padding-bottom: 0;
			}

			&.hidden {
				padding-bottom: 0;

				.our-story--timeline-item-inner {
					height: 0;
				}

				.flourish {
					display: none;
				}
			}
			.flourish {
				position: absolute;
				top: 11px;
				width: 33px;
				height: 33px;
				border-radius: 10px 0 10px 0;
				display: block;
				left: -39px;

				@include bp(lg) {
					left: -39px;
				}

				&:after {
					content: "";
					width: 22px;
					height: 22px;
					background-color: $color-red;
					position: absolute;
					top: 0;
					right: -31px;
					border-radius: 0 7px 0 7px;

				}
			}

			&:first-child {
				.flourish {
					width: 44px;
					height: 47px;
					top: -1px;
					left: -3px;
					border-radius: 0;
					background-color: white;

					&:after {
						width: 44px;
						height: 44px;
						top: 0px;
						left: 0px;
						border-radius: 15px 0 15px 0;
						background-color: $color-red;
					}
				}
			}
		}
		&-more {
			width: 100%;
			height: 250px;
			display: flex;
			align-items: flex-end;
			justify-content: flex-start;
			position: absolute;
			bottom: 0;
			left: 0;
			padding-top: 30px;
			background: rgb(255,255,255);
			background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);

			.btn.btn--primary {
				margin-bottom: 50px;
				margin-left: 9px;
				border-radius: 7px;
				padding-left: 50px;
				padding-right: 50px;
			}

			&.hidden {
				opacity: 0;
				pointer-events: none;
			}
		}
	}

	.columns--two {
		.col {
			&:nth-child(1) {
				@include bp(lg) {
					flex: 0 1 34.5%;
				}
			}
			&:nth-child(2) {
				@include bp(lg) {
					flex: 0 1 57.25%;
					margin-left: auto;
				}
			}
		}
	}
}