.mobile-nav {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 50;
	padding-top: 0;

	@include bp(lg) {
		display: none;
	}

	select {
		position: relative;
		flex: 0 1 58%;
		margin-right: 30px;
		width: 100%;
		border-radius: 0;
		z-index: 10;
		border: none;
		font-size: 12px;
		background-color: white;
		padding: 20px 13px;
		text-transform: uppercase;
		appearance: none;
		background-image: url(img/select-arrow-down.svg);
		background-repeat: no-repeat;
		background-position: calc(100% - 16px) center;
		box-sizing: border-box;
		margin-bottom: 10px;
	}

	input[type="submit"] {
		@include buttonSecondary();

		@include bp(lg) {
			padding: 0 52px;
			box-sizing: border-box;
			flex: 0 1 calc(42% - 30px);
		}
	}

	.btn--map {
		margin-bottom: 10px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}