// Center block
@mixin center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

// // Column width with margin
// @mixin column-width($numberColumns: 3) {
// 	width: map-get($columns, $numberColumns) - ( ( $columns__margin * ( $numberColumns - 1 ) ) / $numberColumns );
// }
@mixin bp($breakpoint) {

	// If the key exists in the map
	@if map-has-key($breakpoints, $breakpoint) {

		// Prints a media query based on the value
		@media #{inspect(map-get($breakpoints, $breakpoint))} {
			@content;
		}
	}

	// If the key doesn't exist in the map
	@else {
		@warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
		+"Available breakpoints are: #{map-keys($breakpoints)}.";
	}
}

/**
* Buttons
*/
@mixin button() {
	min-height: 51px;
	padding-left: 30px;
	padding-right: 30px;
	border-radius: 0;
	line-height: 51px;
	font-size: 14px;
	display: inline-block;
	border: none;
	color: white;
	transition: background-color .3s;
	cursor: pointer;
	text-decoration: none;
	font-family: $font--chromate;
	text-transform: uppercase;
	width: 100%;
	text-align: center;

	@include bp(xs) {
		padding-left: 56px;
		padding-right: 56px;
	}

	@include bp(lg) {
		padding-left: 65px;
		padding-right: 65px;
		line-height: 52px;
		border-radius: 0;
		font-size: 16px;
	}

	&:active,
	&:focus {
		border-color: $color-red-dark;
	}

	.mobile-only {
		display: block;

		@include bp(xs) {
			display: none;
		}
	}

	.desktop-only {
		display: none;

		@include bp(xs) {
			display: block;
		}
	}
}

@mixin buttonPrimary() {
	@include button();

	background-color: $color-gold-dark;
	border: 2px solid transparent;

	&:hover,
	&:focus {
		background-color: $color-green;
		border: 2px solid transparent;
		color: $color-white;
	}
}

@mixin buttonPrimaryArrow() {
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 18px 0 20px;
	min-height: 44px;
	line-height: 44px;
	color: $color-white;

	svg {
		margin-left: 34px;

		path {
			stroke: $color-white;
		}
	}
}

@mixin buttonPrimaryHollow() {
	background-color: transparent;
	color: $color-black;
	border: 2px solid $color-gold-dark;

	&:hover,
	&:focus {
		background-color: $color-gold-dark;
		color: $color-white;
	}
}

@mixin buttonPrimaryHollowArrow() {
	color: $color-red;
	padding: 0 12px 0 24px;

	svg {
		margin-left: 53px;
		position: relative;
		top: 3px;

		path {
			stroke: $color-red;
		}
	}
	&:hover,
	&:focus {
		background-color: $color-red;
		color: $color-white;
		border: 2px solid $color-red;

		svg {
			path {
				stroke: $color-white;
			}
		}
	}
}

@mixin buttonPrimaryLarge() {
	@include buttonPrimary();

	@include bp(lg) {
		min-height: 48px;
		padding-left: 24px;
		padding-right: 24px;
		border-radius: 0;
		line-height: 55px;
		font-size: 16px;
	}

	@include bp(xlg) {
		padding-left: 39px;
		padding-right: 39px;
	}
}

@mixin buttonSecondary() {
	@include button();

	background-color: $color-gold;
	border: 2px solid transparent;
	color: $color-black;

	&:hover,
	&:focus {
		background-color: $color-blue;
		border: 2px solid transparent;
		color: $color-white;
	}
}

@mixin buttonSecondaryArrow() {
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 18px 0 20px;
	min-height: 44px;
	line-height: 44px;
	color: $color-black;

	svg {
		margin-left: 34px;

		path {
			stroke: $color-black;
		}
	}
}

@mixin buttonSecondaryHollow() {
	background-color: transparent;
	color: $color-white;

	&:hover,
	&:focus {
		background-color: $color-black;
		color: $color-white;
	}
}

@mixin buttonSecondaryHollowArrow() {
	color: $color-white;
	padding: 0 20px 0 24px;

	svg {
		margin-left: 32px;

		path {
			stroke: $color-white;
		}
	}
	&:hover,
	&:focus {
		background-color: $color-white;
		border-color: $color-white;
		color: $color-black;

		svg {
			path {
				stroke: $color-black;
			}
		}
	}
}

@mixin buttonTertiary() {
	background-color: $color-blue;
	color: $color-white;

	&:hover,
	&:focus {
		background-color: $color-gold;
		color: $color-black;
	}
}

@mixin buttonTertiaryHollow() {
	background-color: transparent;
	color: $color-white;
	border: 2px solid $color-white;

	&:hover,
	&:focus {
		background-color: $color-green;
		color: $color-white;
		border: 2px solid $color-green;
	}
}

@mixin buttonReset() {
	background-color: transparent;
	color: $color-red;
	border: 2px solid $color-red;
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 15px 0 15px;
	min-height: 44px;
	line-height: 44px;
	border-radius: 15px;
	margin-left: auto;

	svg {
		margin-right: 15px;
	}

	&:hover {
		path {
			fill: $color-white;
		}
	}
}

@mixin buttonMap() {
	background-color: $color-white;
	color: $color-black;
	border: none;
	display: inline-flex;
	font-family: $font--poppins-light;
	text-transform: unset;
	padding: 15px;
	font-size: 11px;
	line-height: 1.2;
	justify-content: flex-start;
	align-items: flex-start;

	span {
		padding-top: 4px;
	}

	strong {
		font-family: $font--poppins-medium;
	}

	svg {
		margin-right: 15px;

		&:last-child {
			margin-left: auto;
			margin-right: 0;
			margin-top: 2px;
		}
	}

	&:hover {
		background-color: $color-gold;
		color: $color-black;
	}
}

@mixin buttonEmail() {
	color: white;
	background-color: $color-red;
	border: 2px solid $color-red;
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 15px 0 15px;
	min-height: 44px;
	line-height: 44px;
	border-radius: 15px;
	margin-left: auto;

	svg {
		margin-right: 15px;

		&:last-child {
			margin-right: 0;
			margin-left: 15px;
			margin-top: -2px;
		}
	}

	&:hover,
	&:focus {
		background-color: $color-red-dark;
		border: 2px solid $color-red-dark;
		color: $color-white;
	}
}

@mixin buttonTelephone() {
	color: $color-red;
	background-color: transparent;
	border: 2px solid $color-red;
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 15px 0 15px;
	min-height: 44px;
	line-height: 44px;
	border-radius: 15px;
	margin-left: auto;

	svg {
		margin-right: 15px;

		&:last-child {
			margin-right: 0;
			margin-left: 15px;
			margin-top: -2px;
		}
	}

	&:hover,
	&:focus{
		background-color: $color-red-dark;
		border: 2px solid $color-red-dark;
		color: $color-white;

		svg {
			&:first-child {
				path {
					fill: $color-white;
				}
			}
			&:last-child {
				path {
					stroke: $color-white;
				}
			}
		}
	}
}