.featured-products {
	padding-bottom: 40px;
	position: relative;
	z-index: 10;

	&:before {
		content: '';
		position: absolute;
		background-image: url("img/bottom-dec.svg");
		background-size: cover;
		width: 100%;
		height: 397px;
		left: 0;
		bottom: -90px;
	}

	@include bp(lg) {
		padding-top: 180px;
		padding-bottom: 180px;
	}

	&--inner {
		position: relative;
		color: $color-white;
	}

	&--script {
		@include bp(lg) {
			position: absolute;
			top: -188px;
			left: -138px;
		}
	}

	.container {
		position: relative;
		z-index: 10;

		@include bp(lg) {
			max-width: 1330px;
		}
	}

	h2 {
		color: $color-black;
		margin: 0 0 10px 0;
		text-transform: uppercase;
		padding: 0 7px;

		@include bp(lg) {
			letter-spacing: 1.5px;
			margin-top: 10px;
			font-size: 40px;
			margin-bottom: 20px;
		}
	}

	.btn {
		@include bp(lg) {
			width: auto;
			padding: 0 50px;
		}
	}

	img {
		&.desktop-only {
			@include bp(lg) {
				flex: 0 1 50%;
				margin-right: 58px;
			}
		}
	}

	&--content {
		text-align: center;
	}

	&--mark {
		img {
			max-width: 240px;
			margin-left: 30px;

			@include bp(lg) {
				max-width: 400px;
				margin-left: 100px;
			}
		}
	}

	.slick-track {
		@include bp(lg) {
			width: 100% !important;
			display: flex !important;
			transform: none !important;
			flex-wrap: wrap !important;
		}
	}

	.slick {
		margin-bottom: 30px;
	}

	.slide {
		@include bp(lg) {
			padding-right: 0px;
			flex: 0 1 33.3333% !important;
			left: 0 !important;
			width: 33.3333% !important;
			opacity: 1 !important;
		}

		//&:before {
		//	content: url('img/lef-border-small.svg');
		//	left: 0;
		//	position: absolute;
		//	top: 0;
		//}

		&:before {
			@include bp(lg) {
				content: url('img/lef-border-small.svg');
				left: 0;
				position: absolute;
				top: 15px;
			}
		}

		&--indicator {
			width: 70px;
			height: 70px;
			background-color: $color-red;
			display: inline-flex;
			color: white;
			font-size: 45px;
			justify-content: center;
			align-items: center;
			border-radius: 20px 0 20px 0;
			margin-bottom: 30px;
		}

		&--inner {
			padding: 5px 0 55px 0;
			color: $color-white;
			text-align: left;

			@include bp(lg) {
				margin-top: 0;
				padding: 50px 30px 15px 30px;
			}

			img {
				width: 100%;
				height: 210px;
				object-fit: cover;
				margin-bottom: 20px;

				@include bp(lg) {
					height: 320px;
					margin-bottom: 35px;
				}
			}

			.btn {
				width: calc(100% - 60px);
				margin: 0 30px;
				color: $color-white;
			}
		}

		h2 {
			color: $color-black;

			@include bp(lg) {
				font-size: 24px;
				font-weight: normal;
				margin-bottom: 27px;
			}
		}

		h3 {
			color: $color-black;
			font-size: 18px;
			line-height: 1.35;
			margin-top: 0px;
			text-transform: uppercase;

			@include bp(lg) {
				font-size: 24px;
				padding: 0;
				margin: 0;
				line-height: 1.25;
			}
		}

		h4 {
			font-family: $font--chromate;
			color: $color-gold-dark;
			font-size: 16px;
			text-transform: uppercase;
			margin-bottom: 10px;
		}

		p {
			color: $color-black;
			font-size: 12px;

			@include bp(lg) {
				font-size: 16px;
				margin: 20px 0 0 0;
				line-height: 1.85;
				letter-spacing: -0.4px;
			}
		}
	}

	.next-arrow,
	.prev-arrow {
		width: 50px;
		height: 50px;
		background-color: $color-gold;
		border: 2px solid $color-gold;
		border-radius: 0;
		transform: none;
		cursor: pointer;
		position: absolute;
		top: auto;
		right: 0;
		bottom: 0;
		z-index: 30;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all 0.5s;
		content: 'data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="15.943" height="27.643" viewBox="0 0 15.943 27.643"><path d="M-1827.615-8036.021l12.76,12.761-12.76,12.761" transform="translate(1828.675 8037.082)" fill="none" stroke="#fff" stroke-width="3"/></svg>';

		@include bp(sm) {
			bottom: 0;
		}

		@include bp(lg) {
			display: none !important;
		}

		&:hover {
			background-color: $color-green;
			border: 2px solid $color-green;
		}

		svg {
			position: relative;
			right: -2px;
			width: 11px;
			height: 19px;

			@include bp(lg) {
				width: 16px;
				height: 28px;
			}

			path {
				@include bp(lg) {
					stroke: $color-black;
				}
			}
		}

		&.slick-disabled {
			opacity: 0.75;
		}
	}

	.prev-arrow {
		left: 0;

		@include bp(lg) {
			left: auto;
			right: 112px;
			bottom: 32px;
		}

		svg {
			position: relative;
			right: 0;
			left: -2px;
			transform: rotate(180deg);
		}
	}

	.slick-dots {
		display: flex;
		padding: 0;
		margin: 0;
		height: 50px;
		padding-left: 60px;
		padding-right: 60px;
		justify-content: center;
		align-items: center;
		margin-top: -12px;

		@include bp(lg) {
			display: none !important;
		}

		li {
			list-style: none;
			margin: 0 7.5px;
			width: 10px;
			height: 10px;
			overflow: hidden;

			@include bp(lg) {
				width: 17px;
				height: 17px;
				margin: 0 17px 0 0;
			}

			&.slick-active {
				button {
					background-color: $color-gold;
					opacity: 1;
				}
			}
		}

		button {
			width: 10px;
			height: 10px;
			border-radius: 0;
			border: 0;
			font-size: 0;
			display: block;
			opacity: 0.4;
			cursor: pointer;

			@include bp(lg) {
				width: 17px;
				height: 17px;
			}
		}
	}

	&--book {
		padding-top: 0;
		padding-bottom: 0;
		margin-bottom: -30px;

		&:before {
			content: '';
			position: absolute;
			background-image: url("img/bottom-dec.svg");
			background-size: cover;
			width: 100%;
			height: 397px;
			top: -169px;
			left: 0;
			z-index: 1;
		}

		.slide {
			p {
				margin-bottom: 0;

				&.availability-text {
					text-transform: uppercase;

					span {
						color: $color-black;
						padding: 5px;

						&:first-child {
							color: $color-gold-dark;
						}
					}
				}
			}

			&:before {
				display: none;
			}
		}
	}
}

.template-private-dining {
	.featured-products--book {
		padding-bottom: 100px;

		@include bp(lg) {
			padding-top: 133px;
			padding-bottom: 160px;
		}

		&:before {
			background-image: none;
			background-color: $color-white;
			width: 100%;
			height: auto;
			left: 0;
			bottom: 0;
			top: 42%;
		}
	}
}