.site-header {
    display: block;

    .menu-primary-menu-desktop-left-container,
    .menu-primary-menu-desktop-right-container,
    .menu-primary-menu-mobile-container {
        & > ul {
            position: fixed;
            z-index: 9999;
            transition: right 0.3s;
            right: -100%;
            width: 100%;
            top: 146px;
            background-color: $color-black;

            @include bp(lg) {
                position: relative;
                transition: unset;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                right: 0;
                top: 0;
                margin: 0;
                padding: 0;
                background-color: transparent;
            }

            > li {
                border-top: 1px solid rgba(255, 255, 255, .15);
                list-style: none;

                @include bp(lg) {
                    margin: 0 20px;
                    border-top: 0px;
                }

                @include bp(xlg) {
                    margin: 0 30px;
                }

                &.current-menu-item,
                &.current-menu-ancestor {
                    > a {
                        &:before {
                            @include bp(lg) {
                                width: 75px;
                            }
                        }
                    }
                }

                &.menu-item-has-children {
                    > a {
                        padding-right: 38px;
                        background-image: url(img/nav-arrow-closed.svg);
                        background-repeat: no-repeat;
                        background-position: calc(100% - 20px) center;

                        @include bp(lg) {
                            padding-right: 0;
                            background-image: none;
                        }

                        &:hover {
                            background-image: url(img/nav-arrow-open-new.svg);

                            @include bp(lg) {
                                background-image: none;
                            }
                        }
                    }
                }

                > a {
                    padding: 18px;
                    color: $color-white;
                    font-family: $font--chromate;
                    text-transform: uppercase;
                    font-size: 14px;

                    @include bp(lg) {
                        padding: 62px 0 62px 0;
                        color: $color-white;
                        position: relative;
                        font-size: 16px;
                    }

                    &:before {
                        @include bp(lg) {
                            background-color: $color-gold;
                            width: 0px;
                            height: 5px;
                            transform: translateX(-50%);
                            position: absolute;
                            left: 50%;
                            bottom: 0;
                            content: "";
                            font-size: 0;
                            transition: width 0.5s;
                        }
                    }

                    &:hover {
                        color: $color-gold;

                        //&:before {
                        //    @include bp(lg) {
                        //        width: 75px;
                        //    }
                        //}
                    }
                }
            }
            > ul {
                > li {
                    > a {
                        color: $color-white;
                    }
                }
            }
        }
    }

    .menu-primary-menu-desktop-right-container {
        & > ul {
            @include bp(lg) {
                justify-content: flex-start;
            }

            > li {
                &:last-child {
                    @include bp(lg) {
                        margin: 0 0 0 auto;
                        padding: 5px 0 0 0;
                    }

                    a {
                        @include bp(lg) {
                            @include buttonPrimaryLarge();

                            margin: 0;
                            padding-top: 0;
                            padding-bottom: 0;
                        }

                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    ul.menu {
        ul {
            position: relative;
            display: none;
            opacity: 1;
            margin: 0;
            padding: 0;

            @include bp(lg) {
                position: absolute;
                top: 100%;
                left: -999em;
                z-index: 99999;
                opacity: 0;
                top: auto;
                display: block;
            }

            &.sub-menu {
                margin-top: -5px;
                border-left: 4px solid $color-gold-dark;
                padding: 10px 0 20px;

                @include bp(lg) {
                    border-left: 0;

                    &:before {
                        content: '';
                        position: absolute;
                        width: 0;
                        height: 0;
                        border-left: 12px solid transparent;
                        border-right: 12px solid transparent;
                        border-bottom: 12px solid $color-gold;
                        top: -13px;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }

                li {
                    > a {
                        padding: 10px 36px;
                    }

                    &.current-menu-item {
                        > a {
                            color: $color-gold !important;
                            font-weight: 400;
                        }
                    }
                }
            }

            li {

                &:hover>ul,
                &.focus>ul {
                    @include bp(lg) {
                        position: relative;
                        display: block;
                        left: 0;
                        position: absolute;
                    }
                }
            }

            :hover>a,
            .focus>a {
            }

            a:hover,
            a.focus {
            }
        }

        li:hover>ul,
        li.focus>ul {
            display: block;

            @include bp(lg) {
                left: 0;
                width: 280px;
                background-color: white;
                border: 0;
                border-radius: 0;
                box-shadow: none;
                display: flex;
                flex-direction: column;
                padding: 0;
                left: 50%;
                transform: translateX(-50%);
                padding: 20px 0;
                opacity: 1;
                border: 1px solid $color-keyline;
            }

            > li {
                list-style: none;

                > a {
                    font-size: 12px;
                    border-top: 0;
                    color: $color-white;
                    text-transform: uppercase;

                    @include bp(lg) {
                        font-size: 16px;
                        font-family: $font--chromate;
                        border-top: 0;
                        text-align: center;
                        line-height: 32px;
                        color: $color-black;

                        &:hover {
                            color: $color-gold;
                        }
                    }
                }

                > ul {
                    > li {
                        > a {
                            padding: 15px 40px;
                            font-size: 13px;
                            border-top: 2px solid $color-grey;

                            @include bp(lg) {
                                padding: 15px 25px;
                                font-size: 16px;
                                border-top: 0;
                            }
                        }
                    }
                }

            }
        }
    }

    li {
        position: relative;

        i {
            transition: transform 0.3s;
            transform: rotate(0deg);
        }

        &:hover,
        &:focus {
            i {
                transform: rotate(180deg);
            }
        }

        &:hover>a>span,
        &.focus>a>span {

        }

        &.open,
        &.focus {
            >ul {
                @include bp(lg-max) {
                    left: 0;
                    position: relative;
                    width: 100%;
                    margin: 0;
                }
            }
        }
    }

    a {
        display: block;
        text-decoration: none;
        transition: color 0.5s ease-in-out;
        outline: none;

        &:focus {
            outline: none;
        }

        @include bp(lg) {
            font-size: 16px;
        }
    }

    .current_page_item>a,
    .current-menu-item>a,
    .current_page_ancestor>a,
    .current-menu-ancestor>a {

    }

    i.fas.fa-chevron-down {
        font-size: 12px;
        margin-left: 10px;
        margin-right: -3px;
    }
}

.menu-depth-1 {

    margin-bottom: 0;

    @include bp(md-max) {
        margin-top: 30px !important;
        margin-bottom: 0 !important;

    }

    a {
        @include bp(lg-max) {
            font-size: 14px !important;
        }
    }
}

.menu-toggle {
    z-index: 99999;
    margin-top: 11px;
    height: 27px;
    width: 30px;
    position: relative;
    font-size: 24px;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    transition: margin-right 0.5s;

    span {
        display: block;
        width: 33px;
        height: 2px;
        margin-bottom: 9px;
        position: relative;
        background: $color-white;
        border-radius: 0;
        z-index: 1;
        transform-origin: 4px 0px;
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                    background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                    opacity 0.55s ease,
                    width 0.5s ease;

        &:first-child {
            transform-origin: 0% 0%;
        }

        &:nth-last-child(2) {
            transform-origin: 0% 100%;
        }
    }
}

.main-navigation {
    &.toggled {
        .menu-toggle {
            margin-right: 30px;

            span {
                opacity: 1;
                width: 16px;
                transform: rotate(-45deg) translate(-7px, 13px);
                background: $color-white;

                &:nth-child(2) {
                    width: 15px;
                    transform: rotate(45deg) translate(5px, -2px);
                }

                &:nth-child(3) {
                    opacity: 0;
                    transform: rotate(0deg) scale(0.2, 0.2);
                }
            }
        }
    }
}

#primary-menu {
    @include bp(xlg-max) {
        background-color: $color-blue;
        position: fixed;
        right: -100%;
        transition: .3s right;
        height: 100vh;
        width: calc(100% - 35px);
        top: 0;
        padding-top: 130px;
    }

    .main-navigation.toggled & {
        @include bp(xlg-max) {
            right: 35px;
        }
    }

    > li {
        > a {

        }

        &:last-child {
            padding: 30px 18px;

            > a {
                @include buttonPrimaryLarge();

                margin: 0;
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }
}

@include bp(lg) {
    .menu-toggle {
        display: none;
    }

    .main-navigation ul {
        display: flex;
    }
}

nav {
    ul {
        list-style: none;
        margin: 0;
        padding-left: 0;
    }
}

.mobile-duplicate-link {
    @include bp(lg) {
        display: none;

    }
}