.menu-callout {
	display: none;
	position: relative;
	z-index: 25;
	margin-top: -120px;
	padding: 0 0 130px 0;

	@include bp(lg) {
		display: block;
	}

	.container {
		position: relative;
		display: flex;
		flex-direction: row-reverse;
		justify-content: flex-end;

		@include bp(lg) {
			width: 95%;
		}
	}

	.feature-smudge {
		@include bp(lg) {
			//margin-left: -20px;
			margin-left: -45px;
		}
	}

	&--art {
		@include bp(lg) {
			margin-left: 11vw;
		}
	}

	.feature-text {
		@include bp(lg) {
			position: absolute;
			transform: rotate(90deg);
			top: 155%;
			right: 20px;
			transform-origin: top right;
			letter-spacing: 0;
		}
	}

	&--content {
		@include bp(lg) {
			position: relative;
			max-width: 635px;
		}

		h2 {
			color: $color-black;

			@include bp(lg) {
				margin-top: 5px;
				margin-bottom: 20px;
			}
		}

		p {
			color: $color-black;

			@include bp(lg) {
				font-size: 16px;
				letter-spacing: -0.4px;
				margin-bottom: 30px;
				line-height: 1.8;
			}
		}
	}

	&--cta {
		form {
			@include bp(lg) {
				padding-top: 14px;
				//padding-right: 70px;
				display: flex;
				flex-wrap: wrap;
			}

			label {
				color: $color-black;

				@include bp(lg) {
					display: block;
					margin-bottom: 15px;
					flex: 0 1 100%;
				}
			}

			select {
				position: relative;
				flex: 0 1 58%;
				margin-right: 30px;
				width: 100%;
				border-radius: 0;
				z-index: 10;
				border: 2px solid $color-gold-dark;
				font-size: 16px;
				background-color: white;
				padding: 20px 13px;
				text-transform: uppercase;
				appearance: none;
				background-image: url(img/select-arrow-down.svg);
				background-repeat: no-repeat;
				background-position: calc(100% - 16px) center;
				box-sizing: border-box;
			}

			input[type="submit"] {
				@include buttonPrimary();

				@include bp(lg) {
					padding: 0 52px;
					box-sizing: border-box;
					flex: 0 1 calc(42% - 30px);
				}
			}
		}
	}

	&--restaurant,
	&--contact {
		display: block;
		margin-top: -55px;
		padding-bottom: 0;


		@include bp(lg) {
			margin-top: -95px;
		}

		.menu-callout--content {
			background-color: $color-white;
			padding: 15px 30px 20px 30px;
			color: $color-black;
			text-align: center;
			position: relative;
			width: 100%;
			//transform: translateY(-130px);

			&:before {
				content: url('img/left-border-mid.svg');
				left: -15px;
				position: absolute;
				bottom: -20px;
			}

			&:after {
				content: url('img/left-border-mid.svg');
				position: absolute;
				bottom: -20px;
				right: -15px;
				transform: scaleX(-1);
			}

			@include bp(lg) {
				margin: auto;
				max-width: 992px;
				padding: 30px 30px 60px 30px;
			}

			h3 {
				font-size: 24px;
				line-height: 1.2;
				padding-bottom: 30px;
				margin-bottom: 25px;
				position: relative;

				@include bp(lg) {
					font-size: 36px;
					padding-bottom: 0;
				}

				&:after {
					width: 40px;
					height: 4px;
					background-color: $color-gold-dark;
					content: "";
					position: absolute;
					left: 50%;
					transform: translateX(-50%);
					bottom: 0;

					@include bp(lg) {
						display: none;
					}
				}
			}

			p {
				display: none;

				@include bp(lg) {
					display: block;
					position: relative;
					padding-bottom: 30px;
				}

				&:after {
					width: 80px;
					height: 4px;
					background-color: $color-gold-dark;
					content: "";
					position: absolute;
					left: 50%;
					transform: translateX(-50%);
					bottom: 0;
				}
			}

			.btn {
				width: auto;
			}
		}
	}

	&--contact {
		@include bp(lg) {
			margin-top: -70px;
		}

		.menu-callout--content {
			background-color: $color-blue;

			&:before,
			&:after {
				content: none;
			}

			@include bp(lg) {
				padding-top: 45px;
				transform: none;
			}

			p {
				color: $color-white;
				display: block;
				font-size: 16px;
				font-family: $font--chromate;
				line-height: 1.4;

				@include bp(lg) {
					font-size: 24px;
					margin: 0;
					padding: 0;
				}

				&:after {
					display: none;
				}
			}
		}
	}
}

.template-private-dining {
	.art-of-indulgence {
		margin-top: 0;
		z-index: 1;

		&:after {
			content: '';
			position: absolute;
			background-image: url("img/bottom-dec.svg");
			background-size: cover;
			width: 100%;
			left: 0;
			bottom: -200px;
			transform: scale(-1);
			z-index: -1;
			height: 100%;
		}

		&:before {
			content: '';
			position: absolute;
			background-image: url("img/bottom-dec.svg");
			background-size: cover;
			width: 100%;
			height: 100%;
			left: 0;
			top: -255px;
		}

		.container {
			align-items: center;
		}
	}
}