.gallery-panel {

	&--inner {
		position: relative;

		@include bp(lg) {
			padding-top: 105px;
		}
	}

	&--intro {
		position: relative;
		z-index: 1;
		margin-bottom: 30px;

		@include bp(lg) {
			margin-bottom: 30px;
		}

		.col {
			padding-bottom: 0;
		}

		h2 {
			line-height: 1.5;
		}

		.btn {
			@include bp(lg) {
				float: right;
				display: inline-block;
				margin-top: 20px;
			}
		}
	}

	&--item {
		margin-bottom: 20px;
	}

	&--list {
		position: relative;
		z-index: 1;

		.col {
			padding-bottom: 0;

			&:nth-child(1) {
				.gallery-panel--image {
					border-radius: 0 40px;

					@include bp(lg) {
						border-radius: 0 60px;
					}
				}
			}
			&:nth-child(3) {
				.gallery-panel--image {
					border-radius: 40px 0;

					@include bp(lg) {
						border-radius: 60px 0;
					}
				}
			}
			&:nth-child(4) {
				.gallery-panel--image {
					border-radius: 0 40px;

					@include bp(lg) {
						border-radius: 0 60px;
					}
				}
			}
		}
	}

	&--image {
		height: 250px;
		overflow: hidden;
		margin-bottom: 35px;

		@include bp(lg) {
			height: 460px;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&--content {
		padding-bottom: 20px;

		@include bp(lg) {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
		p {
			line-height: 1.5;
			margin-bottom: 10px;
		}
	}

	.columns--two {
		.col {
			&:nth-child(1) {
				@include bp(lg) {
					flex: 0 1 58.25%;
				}
			}
			&:nth-child(2) {
				@include bp(lg) {
					flex: 0 1 38.5%;
				}

				.gallery-panel--item {
					@include bp(lg) {
						padding-left: 48px;
					}
				}
			}
			&:nth-child(3) {
				@include bp(lg) {
					flex: 0 1 40.25%;
				}
			}
			&:nth-child(4) {
				@include bp(lg) {
					flex: 0 1 57.5%;
				}
			}
		}
	}

	&--subpage {
		@include bp(lg) {
			padding-bottom: 120px;
		}

		.gallery-panel--title {
			.col {
				padding-bottom: 10px;
			}
		}
		.gallery-panel--intro {
			@include bp(lg) {
				margin-bottom: 58px;
			}

			.col {
				&:first-child,
				&:last-child {
					@include bp(lg) {
						flex: 0 1 50%;
					}
				}
			}
		}
		.gallery-panel--list {
			.col {
				&:nth-child(1) {
					@include bp(lg) {
						flex: 0 1 41.75%;
					}

					.gallery-panel--image {
						border-radius: 40px 0;

						@include bp(lg) {
							border-radius: 60px 0;
						}
					}
				}

				&:nth-child(2) {
					@include bp(lg) {
						flex: 0 1 58.25%;
					}

					.gallery-panel--item {
						@include bp(lg) {
							padding-left: 0;
						}
					}

					.gallery-panel--image {
						border-radius: 0 40px;

						@include bp(lg) {
							border-radius: 0 60px;
						}
					}
				}

				&:nth-child(3) {
					@include bp(lg) {
						flex: 0 1 58.25%;
					}

					.gallery-panel--image {
						border-radius: 0 40px;

						@include bp(lg) {
							border-radius: 0 60px;
						}
					}
				}

				&:nth-child(4) {
					@include bp(lg) {
						flex: 0 1 41.75%;
					}

					.gallery-panel--image {
						border-radius: 40px 0;

						@include bp(lg) {
							border-radius: 60px 0;
						}
					}
				}
			}
		}

		.columns--two {

		}
	}
}