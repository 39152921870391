.calculator-callout {
	text-align: center;
	padding-top: 50px;
	padding-bottom: 50px;

	@include bp(xs) {
		padding-top: 100px;
	}

	@include bp(lg) {
		padding-top: 160px;
		padding-bottom: 115px;
	}

	.container {
		position: relative;

		@include bp(lg) {
			padding-top: 85px;
			border-top: 2px solid $color-grey;
		}
	}

	svg {
		margin-bottom: 22px;
	}

	h2 {
		margin-bottom: 35px;
	}

}