.contact {
	padding-top: 15px;
	position: relative;
	color: $color-black;

	&:before {
		content: '';
		position: absolute;
		background-image: url("img/bottom-dec.svg");
		background-size: cover;
		width: 100%;
		height: 397px;
		top: -169px;
		left: 0;
		z-index: 1;
	}

	@include bp(lg) {
		padding-top: 40px;
		padding-bottom: 20px;
	}

	&__inner-wrap {
		border: 2px solid transparent;
		max-width: 860px;
		width: 100%;
		margin: auto;
		padding: 70px 0;
		z-index: 1;
		position: relative;
		overflow: hidden;

		.dec-l-top {
			position: absolute;
			left: 0;
			//z-index: 10;
			top: 0;
			z-index: -1;

			&:before{
				content: url('img/left-border-xl.svg');
			}
		}

		.dec-l-bottom {
			position: absolute;
			left: 0;
			bottom: 0;
			top: -15px;
			transform: scaleX(-1) rotate(-180deg);
			z-index: -1;

			&:before{
				content: url('img/left-border-xl.svg');

			}
		}

		.dec-r-top {
			position: absolute;
			right: 0;
			//z-index: 10;
			top: 0;
			z-index: -1;

			&:before{
				content: url('img/right-border-xl.svg');
			}
		}

		.dec-r-bottom {
			position: absolute;
			right: 0;
			bottom: 0;
			//top: -15px;
			transform: scaleX(-1) rotate(-180deg);
			z-index: -1;

			&:before{
				content: url('img/right-border-xl.svg');

			}
		}
	}

	.feature-text {
		display: none;

		@include bp(lg) {
			display: block;
			position: absolute;
			transform: rotate(90deg);
			transform-origin: bottom left;
			top: 290px;
			left: 210px;
			z-index: 20;
		}
	}

	&--intro {
		padding: 0 30px;
		text-align: center;

		h2 {
			margin-bottom: 20px;

			@include bp(lg) {
				margin-top: 5px;
				margin-bottom: 35px;
			}
		}
		p {
			font-size: 12px;

			@include bp(lg) {
				font-size: 16px;
				max-width: 630px;
				margin: auto;
			}
		}
	}
	&--form {
		padding-top: 35px;
		padding-bottom: 60px;

		@include bp(lg) {
			padding-top: 45px;
			padding-bottom: 0;
		}

		.hide_summary {
			text-align: center;
		}

		.gform_wrapper {
			padding: 0 20px;

			form {
				@include bp(lg) {
					flex-direction: column;
					max-width: 810px;
					margin: auto;
				}
			}
			.gform_body {
				@include bp(lg) {
					padding-bottom: 0;
				}

				label {
					font-size: 12px;

					@include bp(lg) {
						font-size: 14px;
					}
				}

				select {
					@include bp(lg) {
						font-size: 16px;
						padding: 18px;
						text-transform: uppercase;
					}
				}

				input {
					&[type="text"],
					&[type="email"],
					&[type="file"],
					&[type="number"] {
						@include bp(lg) {
							font-size: 16px;
							padding: 18px;
						}
					}
				}

				textarea {
					@include bp(lg) {
						height: 215px;
					}
				}
			}
			.gform_footer {
				@include bp(xs) {
					padding: 0 30px;
				}

				@include bp(lg) {
					text-align: center;
					//padding: 0 30px;
				}

				input[type="submit"] {
					@include bp(lg) {
						width: auto;
						margin: auto;
					}

					background-color: $color-blue;
					color: $color-white;

					&:hover,
					&:focus {
						background-color: $color-gold;
						color: $color-black;
					}
				}
			}
			.gform_fields {
				@include bp(lg) {
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
				}
			}
			.gfield {
				margin-bottom: 25px;
				width: 100%;

				@include bp(lg) {
					margin-bottom: 30px;
				}

				&--width-half {
					@include bp(lg) {
						width: 47%;
					}
				}

				&.spacer {
					margin-bottom: 0;
				}
			}
		}
	}
}