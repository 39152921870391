.post-content-block {
	padding-top: 20px;
	padding-bottom: 1px;
	position: relative;
	z-index: 10;
	background-color: $color-white;

	@include bp(lg) {
		padding-top: 30px;
		max-width: 1050px;
		margin: auto;
	}

	.container {
		color: $color-black;

		@include bp(lg) {
			max-width: 970px;
		}
	}
}