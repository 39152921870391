.rich-text {
	@include bp(lg) {
		padding-top: 60px;
	}

	h2 {
		font-size: 20px;
		margin-bottom: 30px;

		@include bp(lg) {
			font-size: 36px;
			margin-bottom: 29px;
		}
	}

	h3 {
		font-size: 24px;
		margin-bottom: 15px;

		@include bp(lg) {
			font-size: 36px;
			margin-bottom: 20px;
		}
	}

	h4 {
		@include bp(lg) {
			margin-bottom: 30px;
		}

		+ ul {
			@include bp(lg) {
				padding-top: 10px;
			}
		}
	}

	p {
		font-size: 12px;
		margin-bottom: 20px;

		@include bp(lg) {
			font-size: 16px;
			margin-bottom: 30px;
		}

		+ h2 {
			margin-top: 52px;
		}

		+ h3 {
			margin-top: 43px;

			@include bp(lg) {
				margin-top: 80px;
			}
		}

		+ h4 {
			margin-top: 35px;

			@include bp(lg) {
				margin-top: 80px;
			}
		}
	}

	ul {
		padding: 28px 0 0 0;
		margin: 0;

		li {
			list-style: none;
			padding-left: 32px;
			position: relative;
			margin-bottom: 20px;
			font-size: 12px;

			@include bp(lg) {
				font-size: 16px;
				margin-bottom: 18px;
			}

			&:before {
				content: "";
				width: 9px;
				height: 9px;
				border-radius: 50%;
				background-color: $color-red-keyline;
				position: absolute;
				top: 2px;
				left: 0;
			}
		}
	}
}
.post-meta {
	+ .post-intro {
		@include bp(lg) {
			padding-top: 20px;
		}
	}
}