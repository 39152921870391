.contact-callout {
	text-align: center;
	padding-top: 50px;
	padding-bottom: 40px;

	@include bp(lg) {
		padding-top: 115px;
		padding-bottom: 90px;
	}

	.container {
		position: relative;
		border-top: 2px solid $color-grey;
		padding-top: 50px;

		@include bp(lg) {
			padding-top: 107px;
		}
	}

	&--content {
		@include bp(lg) {
			margin: auto;
			max-width: 550px;
		}
	}

	svg {
		margin-bottom: 15px;
	}

	h2 {
		margin-bottom: 25px;
	}

	p {
		margin-bottom: 37px;
	}

}

.template-case-study {
	.contact-callout {
		@include bp(lg) {
			padding-top: 50px;
		}

		.container {
			border-top: 0;
		}
	}
}