.contact-panels {
	padding-top: 65px;

	@include bp(lg) {
		padding-bottom: 120px;
	}

	&--list {
		position: relative;
		z-index: 10;
	}

	@include bp(lg) {
		padding-top: 95px;
	}

	&--intro {
		text-align: center;
		padding-bottom: 15px;

		@include bp(lg) {
			padding-bottom: 90px;
		}

		img {
			max-width: 300px;
			margin-left: 65px;
		}
		h2 {
			color: $color-black;
			margin-top: -3px;
		}
	}

	.contact-panel {
		margin-bottom: 30px;
		position: relative;

		@include bp(lg) {
			z-index: 30;
			margin-left: 75px;
			margin-bottom: 160px;
		}

		&.open {
			.contact-panel--icons {
				display: flex;
			}
			.contact-panel--title {
				border-bottom: 2px solid #E0E2E3;

				svg {
					transform: rotate(180deg);
				}
			}
		}

		h2 {
			color: $color-white;

			@include bp(lg) {
				font-size: 40px;
				text-transform: uppercase;
				margin: 8px 0 0 60px;
				letter-spacing: 2px;
			}
		}

		&--content {
			background-color: $color-blue;

			&-top {
				@include bp(lg) {
					&:after {
						content: url('img/left-border-long.svg');
						position: absolute;
						top: -120px;
						right: 50px;
						transform: rotate(90deg);
					}
				}
			}

			@include bp(lg) {
				position: relative;
				z-index: 20;
			}
		}

		&--inner {
			background-color: white;
			position: relative;

			@include bp(lg) {
				padding-left: 50.5%;
				padding-right: 25px;
				padding-top: 190px;
				background-color: transparent;
			}
		}

		&--image {
			height: 195px;

			@include bp(lg) {
				width: 57%;
				height: 490px;
				position: absolute;
				left: 0;
				top: 0;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		&--title {
			border-bottom: 2px solid transparent;
			padding: 14px 20px 16px 20px;
			color: $color-black;
			display: flex;
			justify-content: space-between;
			align-items: center;

			@include bp(lg) {
				padding: 26px 20px 20px 20px;
				border-bottom: 1px solid $color-gold-dark;
				justify-content: center;
				text-align: center;
			}

			h2 {
				margin: 0;

				@include bp(lg) {
					letter-spacing: 0;
					font-size: 36px;
					text-transform: unset;
				}

				span {
					font-size: 16px;
					display: block;

					@include bp(lg) {
						letter-spacing: 0;
						font-size: 36px;
						text-transform: unset;
						display: inline-block;
						margin-right: 15px;
					}

					&:after {
						@include bp(lg) {
							content: " -";
						}
					}
				}
			}

			svg {
				@include bp(lg) {
					display: none;
				}
			}
		}

		&--address {
			@include bp(lg) {
				padding: 25px 67px;
				display: flex;
				color: $color-black;
			}

			.col {
				&:first-child {
					@include bp(lg) {
						flex: 0 1 43%;
						padding-right: 30px;
					}
				}
			}
			h3 {
				color: $color-white;

				@include bp(lg) {
					font-size: 24px;
				}
			}
			p {
				@include bp(lg) {
					margin-bottom: 25px;
				}
			}
			a {
				color: $color-beige;
			}

			&-dining {
				flex-direction: column;
				padding: 20px 30px;
				display: flex;
				color: $color-beige;

				@include bp(lg) {
					padding: 35px 60px;
				}

				& > * {
					margin: 8px 0!important;

				}

				p {
					letter-spacing: -0.4px;
				}

				strong {
					font-family: $font--poppins-semiBold;
				}
			}
		}

		&--icons {
			padding: 27px 24px;
			display: none;
			justify-content: space-between;
			align-items: flex-start;

			@include bp(lg) {
				padding: 0 75px;
			}

			a {
				text-decoration: none;
				color: $color-white;
			}

			.icon {
				width: 50px;
				flex: 0 1 50px;
				text-align: center;

				@include bp(lg) {
					width: 65px;
					flex: 0 1 65px;
				}
			}

			svg {
				width: 50px;
				height: 50px;

				@include bp(lg) {
					width: 65px;
					height: 65px;
					margin-bottom: 10px;
				}
			}

			span {
				font-size: 11px;

				@include bp(lg) {
					font-size: 15px;
				}
			}
		}

		&:nth-child(even) {
			.contact-panel--inner {
				@include bp(lg) {
					padding-left: 25px;
					padding-right: 50.5%;
				}
			}

			.contact-panel--content-top {
				@include bp(lg) {
					&:before {
						content: url('img/left-border-top-long.svg');
						position: absolute;
						left: -35px;
						top: -35px;
					}
				}
				&:after {
					content: none;
				}
			}

			.contact-panel--image {
				@include bp(lg) {
					left: auto;
					right: 0;
				}
			}
		}

		&:last-of-type {
			margin-bottom: 0;
		}
	}
}

.template-private-dining {
	.contact-panels {
		padding-top: 0;
		padding-bottom: 50px;

		@include bp(lg) {
			padding-bottom: 0;
		}

		.contact-panel--title {
			svg {
				display: none;
			}
		}
	}
}