.post-image {
	position: relative;
	z-index: 10;

	@include bp(lg) {
		padding-top: 80px;
		max-width: 1050px;
		background-color: $color-white;
		margin: auto;
	}

	.container {
		@include bp(lg) {
			max-width: 970px;
		}
	}

	img {
		width: 100%;
		height: auto;
	}
}