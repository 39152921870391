.hero {
	position: relative;
	color: $color-white;
	padding-top: 0;

	.container {
		position: static;
	}

	img.mobile-only {
		width: 195px;
	}

	&--content {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		top: 0;
		left: 0;
		z-index: 10;
		padding-top: 90px;
		text-align: center;
		//background: rgb(0,0,0);
		//background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 100%);

		@include bp(lg) {
			padding-top: 0px;
		}

		&-inner {
			width: 100%;
			padding: 0 0 12px 0;
			//margin-top: -50px;

			@include bp(lg) {
				//padding-bottom: 100px;
				margin-top: 100px;
			}
		}

		img {
			@include bp(lg) {
				margin: 0 auto 10px auto;
			}
		}
	}

	&--image {
		position: relative;
		height: 425px;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		@include bp(xs) {
			height: 500px;
		}

		@include bp(lg) {
			height: 650px;
		}

		@include bp(lg) {
			height: 845px;
		}

		&:after {
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			z-index: 5;
			background: rgb(0,0,0);
			background: linear-gradient(180deg, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.2) 50%, rgba(0,0,0,0.5) 100%);
			pointer-events: none;
		}
	}

	&--arrow {
		position: absolute;
		bottom: 0;
		left: 0;
		text-align: center;
		padding-bottom: 30px;
		z-index: 10;
		width: 100%;

		@include bp(lg) {
			display: none;
		}
	}

	h1 {
		margin-top: 0;
		line-height: 1.2;
		margin-bottom: 10px;
	}

	h2 {
		color: $color-white;
		font-size: 20px;
		text-transform: uppercase;
		margin-top: 5px;

		+ h1 {
			margin-top: 12px;

			@include bp(lg) {
				font-size: 45px;
			}
		}
	}

	p {
		margin-bottom: 0;
		line-height: 1.6;

		//@include bp(lg) {
		//	color: $color-gold;
		//}
	}

	&--meta {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		padding: 10px 0 15px 0;

		@include bp(lg) {
			padding-top: 33px;
			font-size: 16px;
		}

		.col {
			color: $color-beige;
			text-transform: uppercase;
			padding: 0 10px;
			border-right: 1px solid $color-white;

			&:last-child {
				border-right: 0;
			}

			font-family: $font--poppins-regular;

			strong {
				color: $color-white;
				font-weight: 400;
			}
		}
	}

	&--subpage,
	&--generic {
		.hero--image {
			@include bp(lg) {
				height: 568px;
			}
		}
		.hero--content {
			img {
				max-width: 195px;

				@include bp(lg) {
					max-width: 99999px;
				}
			}
		}
	}

	&--subpage {
		padding-top: 120px;

		@include bp(lg) {
			padding-top: 140px;
		}
	}

	&--subpage-post {
		padding-top: 90px;
		position: relative;
		z-index: 11;

		@include bp(lg) {
			padding-top: 260px;
		}

		.hero--image {
			height: 268px;
			position: relative;

			@include bp(lg) {
				max-width: 1170px;
				width: 95%;
				margin: auto;
				height: 560px;

				&:before {
					content: url('img/lef-border-small.svg');
					left: -25px;
					position: absolute;
					top: -30px;
				}

				&:after {
					content: url('img/lef-border-small.svg');
					position: absolute;
					bottom: -30px;
					right: -30px;
					left: auto;
					background: none;
					height: 45px;
					width: auto;
					top: auto;
					transform: rotate(180deg);
				}
			}

			//&:after {
			//	opacity: 0.5;
			//}
		}
		.hero--content {
			position: relative;
			padding-top: 0;
			margin-top: -27px;
			background-color: $color-white;
			//padding-bottom: 100px;

			@include bp(lg) {
				max-width: 1050px;
				margin: 0 auto ;
				flex-wrap: wrap;
				padding-bottom: 70px;
				//background-color: $color-white;
			}

			&-inner {
				background-color: $color-blue;
				margin: 0 20px;
				color: $color-black;
				padding: 20px 0;
				margin-top: -27px;
				position: relative;

				@include bp(lg) {
					margin: -155px auto 0 auto;
					max-width: 930px;
					padding-top: 37px;
					padding-bottom: 42px;

					&:before {
						content: url('img/left-border-mid.svg');
						left: -35px;
						position: absolute;
						bottom: -40px;
					}

					&:after {
						content: url('img/left-border-mid.svg');
						position: absolute;
						bottom: -40px;
						right: -35px;
						transform: scaleX(-1);
					}
				}

				h2 {
					text-transform: uppercase;
					font-family: $font--poppins-medium;
					font-weight: bold;
					font-size: 12px;
					color: $color-beige;
					margin-bottom: 15px;

					@include bp(lg) {
						font-size: 16px;
						margin-bottom: 30px;
					}
				}

				h1 {
					color: $color-white;
					font-size: 26px;
					line-height: 1.3;

					@include bp(lg) {
						font-size: 54px;
						line-height: 1.15;
					}
				}
			}
		}
		.hero--art {
			display: none;

			@include bp(lg) {
				width: auto;
				height: auto;
				display: block;
				position: absolute;
				top: -20px;
				right: -108px;
				z-index: 20;
			}
		}
		.hero--content-art {
			display: none;

			@include bp(lg) {
				display: block;
				position: absolute;
				top: -155px;
				left: -130px;
			}
		}
		.hero--content-drip {
			display: none;

			@include bp(lg) {
				display: block;
				margin-top: -2px;
			}
		}
	}
	&--generic {
		padding-top: 30px;
		color: $color-black;
		max-width: 1050px;
		margin: 0 auto;
		background: $color-white;
		z-index: 10;

		@include bp(lg) {
			width: 95%;
			padding-top: 270px;
		}

		h1 {
			@include bp(lg) {
				margin-bottom: 100px;
			}
		}

		.hero--content {
			position: relative;

			@include bp(lg) {
				background: transparent;
			}

			&-inner {
				margin-top: 60px;

				@include bp(lg) {
					margin-top: 0;
					padding-bottom: 0;
				}
			}
		}
	}
}