.logos {
	&--intro {
		@include bp(lg) {
			padding-bottom: 50px;
		}
		@include bp(lg) {
			padding-bottom: 95px;
		}
	}

	&--list {
		.slick-prev,
		.slick-next {
			display: none !important;
		}

		.slick-track {
			display: flex;
			align-items: center;

			@include bp(lg) {
				width: 100% !important;
				flex: 0 1 100%;
				flex-wrap: wrap;
				justify-content: flex-start;
			}
		}

		.slick-list {
			@include bp(lg) {
				flex: 0 1 100%;
			}
		}

		.col {
			display: flex;
			align-items: center;
			justify-content: center;
			padding-bottom: 20px;

			@include bp(sm) {
				flex: 0 1 50%;
				padding-bottom: 50px;
				width: 50% !important;
			}

			@include bp(lg) {
				flex: 0 1 19%;
				width: 19% !important;
				padding-bottom: 60px;
			}

			@include bp(lg) {
				flex: 0 1 20%;
				width: 20% !important;
			}

			&:hover {
				img {
					filter: grayscale(0%);
				}
			}
		}

		img {
			max-width: 120px;
			filter: grayscale(100%);
			transition: filter 0.5s ease-in-out;
		}
	}

	&--subpage {
		.columns--two {
			@include bp(lg) {
				display: flex;
				align-items: center;
			}
		}

		.logos--list {
			@include bp(lg) {
				flex-wrap: wrap;
			}

			.col {
				width: 100%;
				margin-bottom: 30px;

				@include bp(sm) {
					flex: 0 1 50%;
				}

				@include bp(lg) {
					flex: 0 1 33.3333%;
				}
			}

			img {
				@include bp(lg) {
					max-width: 140px;
				}
			}
		}

		.logos--content {
			@include bp(lg) {
				padding-bottom: 60px;
			}

			h2 {
				@include bp(lg) {
					margin-bottom: 30px;
				}
			}
		}
	}
}