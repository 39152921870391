#page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;

    .admin-bar & {
        min-height: calc(100vh - 46px);
        @include bp(md) {
            min-height: calc(100vh - 32px);
        }
    }
}
.decor-web {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-size: contain;
    background-repeat: repeat;
    min-width: 1671px;
    min-height: 1201px;
}

//.page-template-template-book-a-table {
//    background-color: $color-black;
//}

section {
    padding-top: 35px;

    @include bp(lg) {
        padding-top: 55px;
    }

    @include bp(lg) {
        padding-top: 105px;
    }

    &.padding-half {
        @include bp(lg) {
            padding-top: 55px;
        }
    }
}

.template-terms-privacy {
    &.subscribe {
        .hero--generic h1 {
            margin-bottom: 0;
        }

        .generic-content .container:before {
            content: none;
        }
    }
}

.template-about {
    .feature-smudge {
        &.extra-large {
            svg {
            	&:nth-child(1) {
            		@include bp(lg) {
            			right: -22px;
            			left: auto;
            		}
            	}

            	&:nth-child(2) {
            		@include bp(lg) {
            			left: -45px;
            		}
            	}
            }
        }
    }
}

.template-restaurant,
.template-menu,
.template-private-dining {
    .feature-smudge {
        &.extra-large {
            svg {
                &:nth-child(2) {
                    display: none;
                }
            }
        }
    }
}

.template-menu {
    .feature-smudge {
        &.extra-large {
            @include bp(lg) {
                max-width: 456px;
            }
        }
    }
}