h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
	font-family: $font--chromate;
    clear: both;
}

h1,
.h1 {
    font-size: 34px;
	font-weight: 400;
	line-height: 1.5;
	margin: 0.4em 0;
	text-transform: uppercase;

	@include bp(xs) {
		line-height: 1.25;
	}

	@include bp(lg) {
		font-size: 54px;
		letter-spacing: 2.7px;
	}
}

.h1--l {
	font-size: 32px;

	@include bp(lg) {
		font-size: 48px;
	}
	@include bp(lg) {
		font-size: 64px;
	}
}

h2,
.h2 {
	font-weight: 400;
	line-height: 1.5;
	margin: 0.4em 0;
	font-size: 24px;

	@include bp(lg) {
		font-size: 30px;
	}

	@include bp(xs) {
		line-height: 1.25;
	}

	@include bp(lg) {
		font-size: 24px;
	}
}

.h2--l {
	font-size: 24px;

	@include bp(lg) {
		font-size: 36px;
	}

	&-c {
		text-transform: uppercase;

		@include bp(lg) {
			font-size: 40px;
			letter-spacing: 2px;
		}
	}
}

h3,
.h3 {
	font-size: 16px;
	font-weight: 400;
	line-height: 1.5;
	margin: 0.4em 0;

	@include bp(xs) {
		line-height: 1.25;
	}

	@include bp(lg) {
		font-size: 20px;
	}
}

h4,
.h4 {
	font-size: 16px;
	font-weight: 400;
	line-height: 1.4;
	margin: 0.4em 0;

	@include bp(xs) {
		line-height: 1.25;
	}

	@include bp(lg) {
		font-size: 24px;
	}
}

body,
button,
input,
select,
optgroup,
textarea {
	font-size: 1rem;
}

p {
	font-size: 14px;
	margin: 10px 0;
	line-height: 1.7;
	letter-spacing: -0.4px;

	@include bp(xs) {
		font-size: 16px;
		margin: 15px 0;
	}
}
.p {
	&--s {
		font-size: 14px;
	}
	&--l {
		font-size: 16px;
		line-height: 1.5;

		@include bp(xs) {
			line-height: 1.7;
		}

		@include bp(lg) {
			font-size: 20px;
		}
	}
}

dfn,
cite,
em,
i {
	font-style: italic;
}

blockquote {
	margin: 0 1.5em;
}

address {
	margin: 0 0 1.5em;
}

pre {
	margin-bottom: 1.6em;
	max-width: 100%;
	overflow: auto;
	padding: 1.6em;
}

abbr,
acronym {
	cursor: help;
}

mark,
ins {
	text-decoration: none;
}

big {
	font-size: 125%;
}
