.section-buttons-group__select-group {
	display: block;
	width: 100%;
	padding-bottom: 15px;
	margin-bottom: 25px;
	border-bottom: 1px solid $color-black;

	@include bp(lg) {
		display: flex;
		padding-bottom: 57px;
		margin-bottom: 50px;
	}

	p {
		font-size: 12px;
		margin-bottom: 5px;

		@include bp(lg) {
			display: none;
		}
	}
}
.select {
	position: relative;

	&:hover {
		z-index: 20;
	}

	@include bp(lg) {
		margin-bottom: 0;
		width: 220px;
	}

	@include bp(lg) {
		width: 244px;
	}

	&--quantity {
		max-width: 89px;
	}

	&--tab {
		display: none;
	}

	.fas {
		font-size: 18px;
	}

	&__body {
		width: 100%;
		display: none;
		left: 0;
		position: absolute;
		margin-top: -2px;
		top: 100%;
		z-index: 100;
		background-color: white;
		overflow: hidden;
		border: 0 2px 2px 2px solid $color-gold-dark;
		color: $color-black;
		box-sizing: border-box;
	}

	&__icon {
		align-items: center;
		display: flex;
		flex-shrink: 0;
		justify-content: center;
		margin-left: auto;
		text-align: center;
		margin-right: 12px;
		position: absolute;
		top: 18px;
		right: 0;
	}

	&:hover {

		.select__body {
			display: block;
			border-top: none;
			max-height: 250px;
			overflow: auto;
		}

		.select__icon {
			transform: rotate(180deg);
		}
	}

	&__header {
		cursor: pointer;
		display: flex;
		align-items: center;
		padding: 20px 0 20px 20px;
		background-color: white;
		border: 2px solid $color-gold-dark;
		color: $color-black;
		font-size: 12px;
		text-transform: uppercase;
		position: relative;
	}

	&__item,
	&__current {
		text-transform: uppercase;
		cursor: pointer;
		font-size: 12px;
	}

	&__item {
		position: relative;

		&.selected {
			&:before {
				content: "";
				width: 10px;
				height: 10px;
				position: absolute;
				border-radius: 50%;
				left: 7px;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		span {
			display: block;
			width: 100%;
			padding: 15px 20px;
		}

		&--tab {
			border-radius: 0;
			text-align: left;

			&:hover {

			}

			span {
				padding: 0 10px 0 25px;
			}
		}

		&:hover {

			.select__tab-item-text {

			}
		}
	}
}

.select--filter {
	display: none;

	@include bp(lg) {
		display: block;
	}
}
