.intro {
	&--content {
		@include bp(lg) {
			padding-right: 50%;
		}

		h2 {
			@include bp(lg) {
				line-height: 1.55;
				margin-top: 0;
			}
		}

		p {
			@include bp(lg) {
				margin-bottom: 38px;
			}
		}

		.p--l {
			@include bp(lg) {
				margin-top: 25px;
				margin-bottom: 44px;
			}
		}
	}

	&--alternate {
		.container {
			position: relative;

			@include bp(lg) {
				padding-bottom: 65px;
			}
		}

		.flourish--align-br {
			@include bp(lg) {
				width: 146px;
				height: 146px;
				bottom: 10px;
				right: 110px;
				background-color: $color-black;
				border-radius: 0 50px 0 50px;
			}

			&:before {
				@include bp(lg) {
					width: 84px;
					height: 84px;
					background-color: $color-black;
					right: -93px;
					border-radius: 30px 0 30px 0;
				}
			}
		}
	}
}
.template-health-and-safety {
	.intro {
		padding-bottom: 50px;

		@include bp(lg) {
			padding-bottom: 80px;
		}
	}
}