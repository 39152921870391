.post-meta {
	padding: 15px 0 0 0;

	@include bp(lg) {
		padding: 28px 0 0 0;
	}

	&--inner {
		border-bottom: 2px solid $color-grey;
	}

	strong {
		font-weight: 700;
	}

	.columns {
		.col {
			&:last-child {
				@include bp(lg) {
					text-align: right;
				}
			}
		}
	}
}