#page {
    > header {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 500;
        background-color: $color-blue;

        .main-navigation {
            opacity: 0;

            &.ready {
                opacity: 1;
            }
        }

        &.menu-open {
            .site-header--inner {
                border-bottom: 1px solid transparent;
            }

            .site-branding {
                svg {
                    .total {
                        path {
                            @include bp(lg-max) {
                                fill: $color-black;
                            }
                        }
                    }
                }
            }
        }

        //&.scrolled {
        //    //.site-header--inner {
        //    //    background: transparent;
        //    //    background-color: $color-black;
        //    //}
        //
        //    //.site-branding {
        //    //    @include bp(lg) {
        //    //        padding: 25px 36px 15px 36px;
        //    //    }
        //    //}
        //
        //    //.site-branding {
        //    //    img {
        //    //        @include bp(lg) {
        //    //            height: 78px;
        //    //        }
        //    //    }
        //    //    svg {
        //    //        .total {
        //    //            path {
        //    //                fill: $color-black;
        //    //            }
        //    //        }
        //    //    }
        //    //}
        //
        //    .menu-toggle {
        //        span {
        //            background-color: $color-white;
        //        }
        //    }
        //
        //    .menu-primary-menu-desktop-left-container,
        //    .menu-primary-menu-desktop-right-container {
        //        > ul {
        //            > li {
        //                > a {
        //                    @include bp(lg) {
        //                        padding: 60px 0 40px;
        //                    }
        //                }
        //            }
        //        }
        //    }
        //
        //    .menu-primary-menu-desktop-right-container {
        //        > ul {
        //            > li:last-child {
        //                > a {
        //                    @include bp(lg) {
        //                        padding: 0 39px;
        //                    }
        //                }
        //            }
        //        }
        //    }
        //
        //    .main-navigation {
        //        .menu-primary-menu-container {
        //            > ul {
        //                > li {
        //                    > a {
        //                        color: $color-black;
        //                    }
        //
        //                    &:last-child {
        //                        > a {
        //                            @include buttonPrimary();
        //
        //                            margin: 15px;
        //
        //                            width: auto;
        //                            min-height: 48px;
        //                            border-radius: 15px;
        //                            line-height: 48px;
        //                            font-size: 16px;
        //                            color: $color-white;
        //                            padding: 0 15px;
        //                            text-align: center;
        //                            display: block;
        //
        //                            @include bp(lg) {
        //                                padding: 0 64px;
        //                            }
        //                        }
        //                    }
        //                }
        //            }
        //        }
        //    }
        //}

        .site-header--inner {
            margin: auto;
            border-bottom: 1px solid rgba(255, 255, 255, 0.24);
            //background: linear-gradient(180deg, rgba(0,0,0,0.5) 0%, rgba(255,255,255,0) 100%);
            //transition: background-color 0.5s;
            padding: 20px 0 8px 0;

            @include bp(lg) {
                padding: 0;
            }
        }

        .container {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            @include bp(lg) {
                justify-content: center;
                align-items: center;
            }
        }

        .menu-column {
            @include bp(lg) {
                width: calc(50% - 102px);
            }

            &--left {
                @include bp(lg) {
                    text-align: right;
                }
            }

            &--right {
                @include bp(lg) {
                    text-align: left;
                }
            }
        }

        .site-branding {
            position: relative;
            z-index: 99999;
            padding: 0 0 0 9px;

            @include bp(lg) {
                padding: 0 36px;
            }

            img {
                transition: height 0.5s ease-in-out;
                height: 88px;
            }

            svg {
                width: 74px;
                height: 58px;

                @include bp(xs) {
                    width: 310px;
                    height: 36px;
                }

                @include bp(xxlg) {
                    width: 410px;
                    height: 48px;
                }
            }
        }
    }
}

#wpadminbar {
    position: fixed !important;
}
.admin-bar {
    #page {
        > header {
            top: 46px;

            @include bp(md) {
                top: 32px;
            }
        }
    }
}

nav#site-navigation {
    width: auto;
}