.menu-list {
	padding-top: 1px;
	color: $color-black;
	z-index: 10;
	position: relative;
	background-color: $color-white;

	@include bp(lg) {
		padding-top: 20px;
	}

	&--filters {
		.section-buttons-group__select-group {
			@include bp(lg) {
				display: block;
				padding-bottom: 0;
			}
		}
		.select {
			@include bp(lg) {
				width: 100%;
			}

			&__header {
				@include bp(lg) {
					display: none;
				}
			}

			&__body {
				@include bp(lg) {
					position: relative;
					border: 0;
					margin-top: 0;
					background-color: transparent;
					display: flex;
					justify-content: center;
					align-items: flex-start;
				}
			}

			&__item {
				@include bp(lg) {
					font-family: $font--chromate;
					font-size: 24px;
					text-transform: unset;
				}

				span {
					@include bp(lg) {
						width: calc(100% - 80px);
						display: block;
						padding: 15px 5px 40px 5px;
						margin: 0 40px;
						position: relative;
					}

					&:after {
						@include bp(lg) {
							width: 0;
							height: 3px;
							background-color: $color-gold-dark;
							position: absolute;
							left: 50%;
							transform: translateX(-50%);
							content: "";
							bottom: 0;
							transition: width 0.5s;
						}
					}
				}

				&.active {
					span {
						@include bp(lg) {
							color: $color-gold-dark;
						}
						&:after {
							@include bp(lg) {
								width: 100%;
							}
						}
					}
				}
			}

			&:hover {
				.select__body {
					@include bp(lg) {
						display: flex;
					}
				}
			}
		}
	}

	&--group {
		opacity: 0;
		transition: opacity 1s;
		display: none;

		&__intro {
			text-align: center;
			padding-top: 7px;
			padding-left: 20px;
			padding-right: 20px;
			padding-bottom: 40px;

			@include bp(lg) {
				padding-top: 18px;
				max-width: 760px;
				padding-left: 0;
				padding-right: 0;
				margin: auto;
				padding-bottom: 0;
			}

			.menu-pdf {
				display: flex;
				align-items: center;
				justify-content: center;

				&__icons {
					display: flex;
					align-items: center;
					flex-direction: column;
					margin-left: 10px;
				}

				a {
					font-family: $font--poppins-semiBold;
					text-decoration: none;
					line-height: 28px;
					letter-spacing: 0.4px;
					display: flex;
					align-items: center;
				}
			}

			h2 {
				color: $color-gold-dark;
				text-transform: uppercase;
				font-size: 20px;

				@include bp(lg) {
					font-size: 32px;
					margin-bottom: 0;
					line-height: 1.6;
				}
			}
			p {
				color: $color-gold-dark;
				font-size: 12px;
				margin-bottom: 20px;
				letter-spacing: -0.45px;

				@include bp(lg) {
					font-size: 18px;
				}
			}
			img {
				width: 130vw;
				max-width: 999999px;
				margin-left: -16vw;
			}
		}
		&__anchors {
			display: none;

			@include bp(lg) {
				display: block;
				text-align: center;
				padding: 15px 130px;
			}

			.btn {
				@include bp(lg) {
					display: inline-block;
					width: auto;
					margin: 0 10px 20px 10px;
					font-family: $font--poppins-semiBold;
					padding: 0 40px;
				}
			}
		}
		&__section {
			margin-top: -10px;

			@include bp(lg) {
				padding-top: 85px;
			}

			+ .menu-list--group__section {
				margin-top: 0;
			}

			&[data-menu-prices="1"] {
				.menu-list--card {
					h2 {
						span {
							display: none;
						}
					}
				}
			}

			&.open {
				.menu-list--group__title {
					padding: 55px 40px 0 15px;
					border-top: 0;

					@include bp(lg) {
						padding: 0 0 40px 0;
					}

					img {
						display: block;
					}
					h3 {
						display: block;
					}

					.feature-smudge {
						display: flex;
						position: absolute;
						width: 173px;
						height: 50px;
						top: 0;
						left: 15px;

						svg {
							left: 0;
						}

						span {
							margin-left: -70px;
						}

						@include bp(lg) {
							width: 268px;
							height: 68px;
							position: relative;
							left: 25px;
						}
					}
					> svg {
						top: 60px;
						right: 15px;
						transform: rotate(180deg);

						path {
							stroke: $color-gold;
						}
					}
				}
				.menu-list--group__content {
					height: auto;
					max-height: 999999px;
				}
			}
		}
		&__title {
			padding: 25px 40px 25px 15px;
			position: relative;
			border-top: 1px solid $color-black;
			cursor: pointer;

			@include bp(lg) {
				text-align: center;
				padding: 0 0 40px 0;
				border-top: 0;
				pointer-events: none;
			}

			.feature-smudge {
				display: none;

				@include bp(lg) {
					display: flex;
					margin: 0 auto 10px auto;
					width: 268px;
					height: 68px;
				}
			}

			&:last-of-type {
				border-bottom: 3px solid $color-black;
			}

			img {
				max-width: 198px;
				position: absolute;
				top: 0;
				left: 15px;
				display: none;

				@include bp(lg) {
					position: relative;
					display: block;
					margin: auto;
					max-width: 260px;
					left: 47px;
				}
			}
			h2 {
				margin-top: -5px;
				text-transform: uppercase;
				font-size: 22px;
				letter-spacing: 1px;
				margin-bottom: 0;

				@include bp(lg) {
					font-size: 40px;
					margin-top: 0;
				}
			}
			h3 {
				margin-top: 0;
				color: $color-gold-dark;
				font-size: 12px;
				text-transform: uppercase;
				font-family: $font--poppins-regular;
				display: none;

				@include bp(lg) {
					display: block;
					font-size: 16px;
					margin-top: 0;
				}
			}
			> svg {
				position: absolute;
				top: 30px;
				right: 15px;

				@include bp(lg) {
					display: none;
				}
			}
		}

		&__content {
			height: 0;
			max-height: 0;
			overflow: hidden;

			@include bp(lg) {
				height: auto;
				max-height: 999999px;
			}
		}

		&__key {
			padding-top: 18px;
			border-bottom: 3px solid $color-black;
			padding-bottom: 3px;

			@include bp(lg) {
				max-width: 560px;
				margin: auto;
			}

			&-title {
				display: flex;
				justify-content: stretch;
				align-items: center;

				span {
					height: 3px;
					background-color: $color-black;
					line-height: 3px;
					font-size: 3px;
					flex-grow: 1;
					flex-basis: 0;
				}
				h3 {
					font-size: 9px;
					text-transform: uppercase;
					font-family: $font--poppins-regular;
					flex-grow: 1;
					flex-basis: 0;
					white-space: nowrap;
					padding: 0 10px;
					text-align: center;
					letter-spacing: 0.7px;

					@include bp(lg) {
						font-size: 14px;
						text-align: center;
					}
				}
			}
			&-icons {
				display: flex;
				justify-content: stretch;
				align-items: flex-start;
				padding-top: 10px;

				.icon {
					text-align: center;
					flex-grow: 1;
					flex-basis: 0;
				}

				p {
					font-size: 9px;
					font-family: $font--poppins-regular;
					margin-top: 2px;
					letter-spacing: 0.4px;

					@include bp(lg) {
						font-size: 16px;
					}
				}
			}
		}

		&__list {
			@include bp(lg) {
				display: flex;
				align-items: flex-start;
				justify-content: flex-start;
				padding-top: 40px;
				flex-wrap: wrap;
			}

			&--card {
				@include bp(lg) {
					flex: 0 1 33%;
				}
			}
		}

		&__subsection {
			padding-top: 20px;

			@include bp(lg) {
				padding-top: 0;
				margin-top: -10px;
				padding-bottom: 80px;
			}

			.menu-list--group__key {
				margin-bottom: 0px;

				@include bp(lg) {
					margin-bottom: 75px;
				}
			}

			&:last-child {
				padding-bottom: 0;
			}

			&-title {
				display: flex;
				justify-content: stretch;
				align-items: center;
				flex-wrap: wrap;

				@include bp(lg) {
					padding: 0 70px 33px 70px;
				}

				span {
					height: 3px;
					background-color: $color-black;
					line-height: 3px;
					font-size: 3px;
					flex-grow: 1;
					flex-basis: 0;
				}

				h2 {
					padding: 0 10px;

					@include bp(lg) {
						font-size: 36px;
						text-transform: uppercase;
						padding: 0 25px;
					}
				}

				.recommended {
					width: 100%;
					margin-top: -10px;
					color: $color-gold-dark;
					text-transform: uppercase;
					font-family: $font--poppins-light;
					font-size: 12px;
					text-align: center;

					@include bp(lg) {
						font-size: 16px;
						margin-top: -15px;
					}
				}
			}

			&-list {
				@include bp(lg) {
					display: flex;
					align-items: flex-start;
					justify-content: flex-start;
					flex-wrap: wrap;
					//margin-bottom: 80px;
				}

				&[data-menu-prices="1"] {
					.menu-list--card {
						h2 {
							span {
								display: none;
							}
						}
					}
				}

				.menu-list--card {
					@include bp(lg) {
						flex: 0 1 33%;
					}
				}
			}
		}

		&[data-menu-prices="1"] {
			.menu-list--card {
				h2 {
					span {
						display: none;
					}
				}
			}
		}

		&.open {
			display: block;
			opacity: 1;

			.menu-list--group__art {
				display: block;

				@include bp(sm) {
					display: none;
				}
			}
		}
	}

	&--card {
		padding: 23px 15px 23px 15px;

		@include bp(lg) {
			padding: 16px 51px;
			flex: 0 1 33.3333%;
			margin-bottom: 24px;
		}

		+ .menu-list--card {
			padding-top: 13px;
		}

		h2 {
			font-size: 16px;
			margin-bottom: 15px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			@include bp(lg) {
				font-size: 24px;
			}

			span {
				color: $color-gold;
				font-family: $font--poppins-regular;
				margin-left: 10px;
				letter-spacing: -0.5px;

				@include bp(lg) {
					font-size: 20px;
					margin-left: 20px;
				}
			}
		}
		p {
			font-size: 12px;
			letter-spacing: 0.4px;

			@include bp(lg) {
				font-size: 16px;
			}
		}

		&__key {
			padding-top: 4px;
			display: flex;
			align-items: center;
			justify-content: flex-start;

			svg {
				width: 30px;
				height: 30px;
				margin-right: 10px;

				@include bp(lg) {
					width: 37px;
					height: 37px;
				}

				.stroke {
					stroke: $color-graphite;
				}
				.fill {
					fill: $color-graphite;
				}
			}
		}

		&.vegetarian {
			.menu-list--card__key {
				svg.vegetarian {
					.stroke {
						stroke: $color-vegetarian !important;
					}
					.fill {
						fill: $color-vegetarian !important;
					}
				}
			}
		}
		&.chilli {
			.menu-list--card__key {
				svg.chilli {
					.stroke {
						stroke: $color-chilli !important;
					}
					.fill {
						fill: $color-chilli !important;
					}
				}
			}
		}
		&.nuts {
			.menu-list--card__key {
				svg.nuts {
					.stroke {
						stroke: $color-nuts !important;
					}
					.fill {
						fill: $color-nuts !important;
					}
				}
			}
		}
	}
}