.map-callout {
	background-color: $color-white;
	padding-top: 75px;
	padding-bottom: 45px;

	@include bp(lg) {
		padding-top: 190px;
		position: relative;
		padding-bottom: 200px;
	}

	.feature-smudge {
		@include bp(lg) {
			margin-left: 0px;
		}
	}

	.container {
		@include bp(lg) {
			position: static;
		}
	}

	&--intro {
		text-align: center;
		color: $color-black;

		@include bp(lg) {
			display: none;
		}

		img {
			max-width: 240px;
			margin-left: 30px;

			@include bp(lg) {
				max-width: 400px;
				margin-left: 98px;
			}
		}

		h2 {
			text-transform: uppercase;
			margin-top: 0;
		}
	}
	&--map {
		margin: 0 -15px;
		position: relative;

		@include bp(lg) {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 5;
			padding-top: 190px;
			margin: 0;
		}

		&:after {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			content: "";
			background: rgb(255,255,255);
			background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 100%);
			pointer-events: none;
			z-index: 10;

			@include bp(lg) {
				display: block;
				margin-top: 190px;
				height: calc(100% - 190px);
			}
		}

		img {
			width: 100%;
			height: 360px;
			object-fit: cover;

			@include bp(lg) {
				width: 100%;
				//height: 920px;
				height: 100%;
				position: relative;
			}
		}
	}
	&--logo {
		display: none;

		@include bp(lg) {
			padding: 30px 30px 20px 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-bottom: 2px solid #E0E2E3;
		}

		img {
			@include bp(lg) {
				width: 175px;
			}
		}
	}
	&--contact {
		@include bp(lg) {
			position: relative;
			z-index: 30;
			padding-left: 95px;
		}

		h2 {
			@include bp(lg) {
				font-size: 40px;
				text-transform: uppercase;
				margin: 8px 0 0 60px;
				letter-spacing: 2px;
				color: $color-black;
			}
		}

		&-inner {
			background-color: white;
			margin: -40px 5px 0 5px;
			position: relative;
			z-index: 20;
			border: 1px solid #D7ECFC;

			@include bp(lg) {
				width: 450px;
				margin: 55px 0 0 auto;
			}
		}
		&-title {
			border-bottom: 2px solid #E0E2E3;
			padding: 14px 20px 16px 20px;
			color: $color-black;
			display: flex;
			justify-content: space-between;
			align-items: center;

			@include bp(lg) {
				padding: 30px 20px;
				border-bottom: 0;
			}

			h2 {
				margin: 0;

				@include bp(lg) {
					letter-spacing: 0;
					font-size: 36px;
				}
			}
			h3 {
				margin: 0;

				@include bp(lg) {
					text-transform: uppercase;
					font-size: 16px;
				}
			}

			@include bp(lg) {
				justify-content: center;
				text-align: center;
			}

			svg {
				@include bp(lg) {
					display: none;
				}
			}
		}

		&-icons {
			padding: 15px 24px;
			display: flex;
			justify-content: space-between;
			align-items: flex-start;

			@include bp(lg) {
				padding: 0 75px;
			}

			a {
				text-decoration: none;
				color: $color-black;
			}

			.icon {
				width: 50px;
				flex: 0 1 50px;
				text-align: center;

				@include bp(lg) {
					width: 65px;
					flex: 0 1 75px;
				}

				svg {
					.stroke {
						stroke: $color-black;
					}
					.fill {
						fill: $color-black;
					}
				}
			}

			svg {
				width: 50px;
				height: 50px;

				@include bp(lg) {
					width: 65px;
					height: 65px;
					margin-bottom: 10px;
				}

				.stroke {
					transition: stroke 0.5s;
				}
				.fill {
					transition: fill 0.5s;
				}
			}

			span {
				font-size: 11px;

				@include bp(lg) {
					font-size: 16px;
				}
			}
		}
		&-buttons {
			padding: 0px 25px 15px 25px;

			@include bp(lg) {
				padding: 40px 100px 50px 100px;
			}

			p {
				@include bp(lg) {
					margin: 0;
				}
			}

			.btn {
				background-color: $color-black;

				@include bp(lg) {
					padding: 0 30px;
				}
			}
		}
	}
}