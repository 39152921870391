.newsletter {
	position: relative;
	z-index: 1;

	@include bp(lg) {
		padding-top: 0;
	}

	.container {
		@include bp(lg) {
			width: 95%;
		}
	}

	&--inner {
		background-color: $color-blue;
		color: $color-white;
		padding: 13px 23px 23px 23px;

		@include bp(lg) {
			padding: 35px;
			text-align: center;

			&:before {
				content: url('img/left-border.svg');
				left: -11px;
				position: absolute;
				top: -31px;
			}

			&:after {
				content: url('img/border-right.svg');
				position: absolute;
				bottom: -35px;
				right: -12px;
			}
		}

		p {
			@include bp(lg) {
				margin-top: 10px;
			}

			&:last-child {
				margin-bottom: 0;
			}

			.btn {
				margin-top: 17px;
				@include bp(lg) {
					width: auto;
				}
			}
		}

		form {
			text-align: left;
		}
	}

	.gform_wrapper {
		.gform_body {
			input[type="text"],
			input[type="email"] {
				@include bp(lg) {
					padding: 20px;
				}
			}
		}
	}

	.gform_body {
		@include bp(lg) {
			padding: 11px 0 11px 60px;
		}
	}

	.gform_fields {
		@include bp(lg) {
			display: flex;
			margin: 0 -15px;
		}

		.gfield {
			@include bp(lg) {
				flex-grow: 1;
				padding: 0 15px;
			}
		}
	}

	//&--drip {
	//	text-align: right;
	//
	//	@include bp(lg) {
	//		height: 92px;
	//		overflow: hidden;
	//		display: flex;
	//		align-items: flex-start;
	//		position: relative;
	//	}
	//
	//	svg {
	//		@include bp(lg) {
	//			position: absolute;
	//			top: -75px;
	//			left: 197px;
	//		}
	//
	//		&:last-child {
	//			@include bp(lg) {
	//				left: auto;
	//				right: -75px;
	//			}
	//		}
	//	}
	//}
}