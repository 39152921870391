.invisible {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.js-tablist-wrapper {
	margin: 0;
	overflow-x: hidden;

	@include bp(xs) {
		margin: 0;
	}
}

.example-tabs-tabs__list {
	margin: 0;
	padding: 0;
	display: flex;
	align-items: stretch;
}
.example-tabs-tabs__item {
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1;

	&:last-child {
		.example-tabs-tabs__link {
			border-right: 0;
		}
	}
}
.example-tabs-tabs__link {
	display: inline-block;
	padding: 13px;
	background: $color-gold-dark;
	width: 100%;
	cursor: pointer;
	color: $color-white;
	font-size: 9px;
	text-align: center;
	flex: 1;
	border-right: 1px solid rgba(255, 255, 255, 0.2);
	text-decoration: none;

	&:focus,
	&:hover {
		color: $color-white;
	}

	svg {
		height: 21px;
	}

	span {
		display: block;
		padding-top: 10px;
	}
}
[aria-selected="true"].example-tabs-tabs__link {
	color: $color-white;
	background: $color-blue;
}
.example-tabs-tabs__content {
	background: $color-blue;
	padding: 20px;
}
[aria-hidden="true"].example-tabs-tabs__content {
	display: none;
}