/* just for example as nested accordion */
[data-accordion-prefix-classes="minimalist-css"] {
	margin-left: 0;
}

.minimalist-css-accordion__title {
	margin: 0;
	position: relative;
}

.minimalist-accordion__header,
.minimalist-noanim-accordion__header,
.minimalist-css-accordion__header {
	display: block;
	background: none;
	border: 0;
	font-family: inherit;
	cursor: pointer;
	font-size: 24px;
	line-height: 1.5;
	margin: 0;
	padding: 0;
	text-align: left;
	width: 100%;
	font-weight: normal;

	svg {
		transform: rotate(180deg);

		path {
			stroke: $color-red;
		}
	}
}

.minimalist-css-accordion__header {
	font-size: 19px;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	background-color: transparent;
	border-top: 2px solid $color-graphite-alternate;
	padding: 25px 20px;

	@include bp(xs) {
		font-size: 24px;
	}

	@include bp(lg) {
		overflow: hidden;
		padding: 32px 24px;
	}

	span {
		width: 35px;
		height: 35px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $color-beige;
		pointer-events: none;
		flex: 0 1 35px;
		min-width: 35px;
		margin: 0 0 0 20px;

		path {
			stroke: $color-red;
		}

		svg {
			width: 25px;
			height: 11px;
		}
	}

	&[aria-expanded="true"] {
		background-color: $color-sky;
		color: $color-black;

		&:before {
			display: none;
		}

		@include bp(lg) {

		}
		span {
			background-color: $color-gold-dark;
		}

		svg {
			width: 25px;
			height: 11px;
			transform: rotate(0deg);

			path {
				stroke: $color-white;
			}
		}
	}
}

.minimalist-accordion__header:focus,
.minimalist-noanim-accordion__header:focus,
.minimalist-css-accordion__header:focus {
	outline: 1px dotted;
}
.minimalist-accordion__panel {
	display: block;
	overflow: hidden;
	opacity: 1;
	max-height: 100em;
	/* magic number for max-height = enough height */
	visibility: visible;
	-webkit-transition-delay: 0s;
	transition-delay: 0s;
	margin: 0;
	padding: 0;
}

/* This is the hidden state */

[aria-hidden=true].minimalist-accordion__panel {
	display: block;
	max-height: 0;
	opacity: 0;
	visibility: hidden;
	margin: 0;
	padding: 0;
}

.minimalist-css-accordion__panel {
	display: block;
	overflow: hidden;
	opacity: 1;
	transform: scaleY(1);
	max-height: 40em;
	/* magic number for max-height = enough height */
	visibility: visible;
	-webkit-transition-delay: 0s;
	transition-delay: 0s;
	margin: 0;
	background-color: $color-sky;
	color: $color-black;
	padding: 0 20px 20px 20px;

	@include bp(xs) {
		padding: 0 30px 30px 30px;
	}

	@include bp(lg) {
		background-color: $color-sky;
		color: $color-black;
		padding: 0 24px 24px 24px;
		text-align: left;
	}

	p {
		font-size: 12px;
		margin: 5px 0 15px 0;

		@include bp(lg) {
			font-size: 16px;
			margin: 0 0 30px 0;
		}
	}
}


/* This is the hidden state */

[aria-hidden=true].minimalist-css-accordion__panel {
	display: block;
	opacity: 0;
	transform: scaleY(0);
	max-height: 0;
	visibility: hidden;
	margin: 0;
	padding: 0;
}

.minimalist-noanim-accordion__panel {
	display: block;
}

[aria-hidden=true].minimalist-noanim-accordion__panel {
	display: none;
}
