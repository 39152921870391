/**
*Put all button styling in mixins/mixins.scss
*/
.btn {
	@include button();

	&--primary {
		@include buttonPrimary();

		&.hollow {
			@include buttonPrimaryHollow();

			&.arrow {
				@include buttonPrimaryHollowArrow();
			}
		}

		&.arrow {
			@include buttonPrimaryArrow();
		}

		&.email {
			@include buttonEmail();
		}

		&.telephone {
			@include buttonTelephone();
		}

		&.map {
			@include buttonMap();
		}
	}

	&--secondary {
		@include buttonSecondary();

		&.hollow {
			@include buttonSecondaryHollow();

			&.arrow {
				@include buttonSecondaryHollowArrow();
			}
		}

		&.arrow {
			@include buttonSecondaryArrow();
		}
	}

	&--tertiary {
		@include buttonTertiary();

		&.hollow {
			@include buttonTertiaryHollow();
		}
	}

	&--disabled {
		opacity: .5;
		cursor: not-allowed;
	}

	&--reset {
		@include buttonReset();
	}

	&--map {
		@include buttonMap();
	}
}