.tab-slider {

	&--inner {
		position: relative;
		color: $color-white;
		padding-top: 40px;
		padding-bottom: 40px;

		@include bp(lg) {
			padding-top: 83px;
			padding-bottom: 93px;
		}

		&:after {
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			z-index: 5;
			background-color: rgba(0, 0, 0, 0.6);
			pointer-events: none;
		}
	}

	.container {
		position: relative;
		z-index: 10;
	}

	&--background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	h2 {
		@include bp(lg) {
			margin-bottom: 47px;
		}
	}

	.slide {

		@include bp(lg) {
			padding-right: 0;
		}

		&--indicator {
			width: 70px;
			height: 70px;
			background-color: $color-red;
			display: inline-flex;
			color: white;
			font-size: 45px;
			justify-content: center;
			align-items: center;
			border-radius: 20px 0 20px 0;
			margin-bottom: 30px;
		}

		&--inner {
			@include bp(lg) {
				padding-right: 60%;
			}
		}

		h2 {
			@include bp(lg) {
				font-size: 24px;
				font-weight: normal;
				margin-bottom: 27px;
			}
		}

		p {
			font-size: 16px;
			line-height: 1.5;

			@include bp(lg) {
				margin-bottom: 36px;
			}
		}

		.read-more {
			color: white;
			text-decoration: none;
		}
	}

	.dropdown {
		display: block;

		@include bp(xs) {
			display: none;
		}

		ul {
			overflow: hidden;
		}
	}

	.example-tabs-tabs__list {
		display: none;

		@include bp(xs) {
			display: block;
		}

		.example-tabs-tabs__link {
			background: transparent;
			font-size: 16px;
			text-transform: uppercase;
			color: white;
			padding: 17px 5px 15px 5px;
			position: relative;

			&:after {
				background-color: #d8006b;
				width: 0;
				height: 2px;
				-webkit-transform: translateX(-50%);
				transform: translateX(-50%);
				position: absolute;
				left: 50%;
				bottom: 0;
				content: "";
				font-size: 0;
				-webkit-transition: width .5s;
				transition: width .5s;
			}

			&[aria-selected="true"] {
				&:after {
					width: 100%;
				}
			}
		}
	}

	.js-tablist-wrapper {
		overflow: initial;
	}

	.example-tabs-tabs__item {
		padding-right: 35px;

		&:last-child {
			padding-right: 0;
		}
	}

	.example-tabs-tabs__content {
		background: transparent;
		padding: 40px 0px;

		@include bp(xs) {
			padding: 60px 0;
		}
	}

	.next-arrow,
	.prev-arrow {
		width: 50px;
		height: 50px;
		background-color: $color-red;
		border: 2px solid $color-red;
		border-radius: 50%;
		transform: none;
		cursor: pointer;
		position: absolute;
		top: auto;
		right: 0;
		bottom: 0;
		z-index: 30;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all 0.5s;
		content: 'data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="15.943" height="27.643" viewBox="0 0 15.943 27.643"><path d="M-1827.615-8036.021l12.76,12.761-12.76,12.761" transform="translate(1828.675 8037.082)" fill="none" stroke="#fff" stroke-width="3"/></svg>';

		@include bp(sm) {
			bottom: 0;
		}

		@include bp(lg) {
			width: 70px;
			height: 70px;
		}

		&:hover {
			background-color: $color-red-dark;
			border: 2px solid $color-red-dark;
		}

		svg {
			position: relative;
			right: -2px;
			width: 11px;
			height: 19px;

			@include bp(lg) {
				width: 16px;
				height: 28px;
			}
		}

		&.slick-disabled {
			background-color: transparent;
		}
	}

	.prev-arrow {
		left: 0;

		@include bp(lg) {
			left: auto;
			right: 100px;
			bottom: 0;
		}

		svg {
			position: relative;
			right: 0;
			left: -2px;
			transform: rotate(180deg);
		}
	}

	.slick-dots {
		display: flex;
		padding: 0;
		margin: 0;
		height: 50px;
		padding-left: 60px;
		padding-right: 60px;
		justify-content: center;
		align-items: center;

		@include bp(lg) {
			height: 70px;
			padding-left: 0;
			justify-content: flex-start;
			align-items: center;
		}

		li {
			list-style: none;
			margin: 0 10px;
			height: 12px;

			&.slick-active {
				button {
					background-color: $color-red;
				}
			}
		}

		button {
			width: 12px;
			height: 12px;
			border-radius: 50%;
			border: 0;
			font-size: 0;
			display: block;
		}
	}
}