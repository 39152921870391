.news-intro {
	text-align: center;

	@include bp(lg) {
		padding-top: 40px;
	}

	img {
		max-width: 215px;
		margin-left: 40px;

		@include bp(lg) {
			max-width: 275px;
			margin-left: 70px;
		}
	}

	h2 {
		color: $color-black;
		text-transform: uppercase;
		margin-top: -5px;
		margin-bottom: 0;

		@include bp(lg) {
			font-size: 42px;
			margin-top: 4px;
		}
	}
}
.news-featured {
	position: relative;
	z-index: 10;

	@include bp(lg) {
		padding-top: 60px;
	}

	.container {
		@include bp(lg) {
			display: flex;
			flex-wrap: wrap;
		}
	}

	.feature-text {
		position: absolute;
		top: -15px;
		right: 30px;
		z-index: 20;
		font-size: 30px;

		@include bp(lg) {
			right: auto;
			left: 10px;
			font-size: 80px;
			top: -50px;
		}
	}

	&--mark {
		position: absolute;
		top: -48px;
		right: 0;

		@include bp(lg) {
			right: auto;
			transform: scale(1.75);
			left: 30px;
		}
	}

	&--art {
		display: none;

		@include bp(lg) {
			display: block;
			position: absolute;
			top: -255px;
			right: -120px;
		}
	}

	.news--image {
		height: 150px;

		@include bp(lg) {
			height: 490px;
			flex: 0 1 48.5%;
			padding-left: 80px;
		}
	}

	.news--content {
		padding-bottom: 28px;
		background-color: $color-blue;

		@include bp(lg) {
			flex: 0 1 calc(51.5% + 55px);
			margin: 85px 20px 0 -75px;
			padding: 47px 70px;
			position: relative;
		}

		h3 {
			color: $color-white;
			font-size: 18px;

			@include bp(lg) {
				font-size: 36px;
				line-height: 1.2;
			}
		}

		h4 {
			color: $color-beige;
		}

		p {
			color: $color-white;
			display: block;
			font-size: 12px;
			margin-bottom: 0px;
			margin-top: 13px;
			line-height: 1.6;

			@include bp(lg) {
				font-size: 16px;
				margin-bottom: 55px;
			}

			a {
				color: $color-gold-dark;
				text-transform: uppercase;

				@include bp(lg) {
					@include buttonPrimary;
				}
			}

			&:last-of-type {
				@include bp(lg) {
					margin-bottom: 10px;
				}
			}
		}
	}
	.news--drip {
		display: block;

		img {
			margin-top: -41px;

			@include bp(lg) {
				margin-left: auto;
				margin-top: 0;
				margin-right: 20px;
			}
		}
	}
}
.news-filters {
	text-align: center;
	margin-bottom: 8px;

	@include bp(lg) {
		max-width: 1100px;
		margin: auto;
	}

	h2 {
		color: $color-black;
		font-size: 20px;
		margin-bottom: 13px;

		@include bp(lg) {
			font-size: 36px;
		}
	}

	form {
		text-align: left;
		padding: 20px;
		background-color: $color-blue;
		position: relative;

		@include bp(lg) {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			padding: 20px 20px 30px 20px;

			&:before {
				content: url('img/lef-border-small.svg');
				left: -30px;
				position: absolute;
				top: -30px;
			}
		}

		p {
			margin: 0 0 10px 0;
			font-size: 12px;

			@include bp(lg) {
				font-size: 16px;
				margin: 0 0 8px 0;
			}
		}

		select {
			border: 2px solid $color-blue;
			margin-bottom: 25px;
			padding: 17px 13px;
			text-transform: uppercase;
			cursor: pointer;

			@include bp(lg) {
				width: 288px;
				padding: 21px 25px;
				font-size: 14px;
				margin-bottom: 0;
			}
		}

		.filter-column {
			@include bp(lg) {
				margin-right: 30px;
				display: flex;
				justify-content: flex-end;
				flex-direction: column;
			}

			&:last-child {
				@include bp(lg) {
					margin-top: auto;
					margin-right: 0;
				}
			}
		}

		input[type=submit] {
			line-height: 46px;

			@include bp(lg) {
				width: auto;
				line-height: 56px;
				padding: 0 76px;
			}
		}
	}

	//&--drip {
	//	text-align: left;
	//}
}
.news {
	background: $color-blue;

	@include bp(lg) {
		padding-top: 100px;
		padding-bottom: 135px;
	}

	select {
		position: relative;
		width: 100%;
		border-radius: 0;
		z-index: 10;
		border: 2px solid $color-black;
		font-size: 12px;
		background-color: white;
		padding: 15px 13px;
		appearance: none;
		background-image: url(img/select-arrow-down.svg);
		background-repeat: no-repeat;
		background-position: calc(100% - 12px) center;
	}

	.mobile-post-link {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;

		@include bp(lg) {
			display: none;
		}
	}

	&--inner {
		position: relative;
		border: 0px;
	}

	.container {
		z-index: 2;

		@include bp(lg) {
			max-width: 1310px;
			width: 95%;
		}
	}

	.feature-smudge {
		//margin-left: auto;
		//margin-right: auto;
		//
		//@include bp(lg) {
		//	margin-left: -10px;
		//}
		margin-left: auto;
		margin-right: auto;

		svg {
			left: 35px;
		}

		span {
			margin-left: 0;
		}

		@include bp(lg) {
			margin-left: 0;

			svg {
				left: auto;
			}

			span {
				margin-left: -125px;
			}
		}
	}

	&--intro {
		position: relative;
		z-index: 1;
		text-align: center;

		@include bp(lg) {
			margin-bottom: 10px;
			text-align: left;
		}

		h2 {
			text-transform: uppercase;
			margin-top: -5px;
			margin-bottom: 20px;

			@include bp(lg) {
				font-size: 42px;
				margin-top: 7px;
				margin-left: 14px;
				letter-spacing: 0.75px;
			}
		}

		> .col {
			&:nth-child(2) {
				display: none;

				@include bp(lg) {
					display: block;
					text-align: right;
				}
			}
		}

		.btn {
			@include bp(lg) {
				width: auto;
				margin-left: auto;
				display: inline-block;
				margin-top: 70px;
				padding: 0 48px;
				margin-right: 22px;
			}

			svg {
				margin-left: 13px;
			}
		}
	}

	&--list {
		position: relative;
		z-index: 1;
		//margin-top: 80px;

		@include bp(lg) {
			padding-bottom: 0;
			display: flex;
			align-items: stretch;
		}

		.col {
			display: flex;
			flex-direction: row;
			padding-bottom: 15px;
			flex-wrap: wrap;

			@include bp(lg) {
				display: flex;
				justify-content: flex-start;
				align-items: flex-start;
				align-content: flex-start;
				flex-direction: column;
			}

			&:last-child {
				.news--drip {
					visibility: visible;
				}
			}
		}
	}

	&--image {
		overflow: hidden;
		flex: 0 1 140px;

		@include bp(lg) {
			width: 100%;
			height: 325px;
			min-height: 325px;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;

			&.news-featured--mark {
				@include bp(lg) {
					width: auto;
					height: auto;
					transform: scale(1);
					top: -127px;
					left: -109px;
				}
			}
		}
	}

	&--content {
		flex: 0 1 calc(100% - 140px);
		background-color: $color-white;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding: 18px 14px;
		color: $color-black;
		flex-wrap: wrap;

		@include bp(lg) {
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			padding: 20px 42px;
		}

		h3 {
			font-size: 14px;
			margin-bottom: 5px;
			line-height: 1.3;
			width: 100%;

			@include bp(lg) {
				font-size: 24px;
				margin-bottom: 25px;
			}
		}
		h4 {
			text-transform: uppercase;
			font-family: $font--poppins-semiBold;
			font-weight: 600;
			font-size: 9px;
			color: $color-gold-dark;
			width: 100%;

			@include bp(lg) {
				font-size: 16px;
			}
		}
		p {
			line-height: 1.5;
			margin-bottom: 25px;
			display: none;

			@include bp(lg) {
				display: block;
				margin-top: 0;
			}

			&:last-child {
				@include bp(lg) {
					margin-top: auto;
					justify-self: flex-end;
					align-self: flex-start;
					margin-bottom: 10px;
				}
			}
		}

		.btn {
			@include bp(lg) {
				padding: 0 40px;
			}
		}
	}
	//&--drip {
	//	flex: 0 1 100%;
	//	text-align: right;
	//	visibility: hidden;
	//	pointer-events: none;
	//	display: none;
	//
	//	@include bp(lg) {
	//		flex: unset;
	//		width: 100%;
	//		display: block;
	//	}
	//
	//	svg {
	//		margin-left: auto;
	//
	//		@include bp(lg) {
	//			width: 212px;
	//			height: 158px;
	//			margin-top: -1px;
	//		}
	//	}
	//}
	&--outro {
		padding-top: 10px;
		text-align: center;

		@include bp(lg) {
			display: none;
		}

		.btn {
			width: auto;
			padding: 0 60px;
		}
	}

	&--homepage {
		padding-bottom: 40px;
		background-color: $color-white;
		position: relative;

		@include bp(lg) {
			padding-bottom: 115px;
			margin-top: -15px;
		}

		&:before {
			content: '';
			position: absolute;
			background-image: url("img/bottom-dec.svg");
			background-size: cover;
			width: 100%;
			height: 397px;
			top: -169px;
			left: 0;
			z-index: 1;
		}

		.columns--three {
			> .col {
				display: flex;
				flex-wrap: wrap;

				&:hover {
					.news--content {
						border-color: $color-blue;
					}
				}

				@include bp(lg) {
					flex-direction: column;
					padding-bottom: 30px;
				}

				&:last-child {
					.news--drip {
						display: block;

						@include bp(lg) {
							display: none;
						}
					}
				}
			}
		}

		.news--image {
			flex: 0 1 50%;
			height: 145px;

			@include bp(lg) {
				flex: unset;
				width: 100%;
				height: 330px;
			}
		}

		.news--content {
			flex: 0 1 50%;
			border: 1px solid $color-gold;

			@include bp(lg) {
				flex: 1;
				width: 100%;
				padding-bottom: 30px;
				border-top: none;
			}

			.btn {
				@include bp(lg) {
					line-height: 56px;
				}
			}
		}

		//.news--drip {
		//	display: none;
		//	flex: 0 1 100%;
		//}
	}
}

.template-news {
	.news--list {
		margin-top: 60px;

		@include bp(lg) {
			margin-top: 80px;
		}
	}
}