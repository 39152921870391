.team-list {
	padding-top: 50px;
	padding-bottom: 0;

	@include bp(lg) {
		padding-top: 135px;

	}

	.container {
		position: relative;

		@include bp(lg) {
			padding-bottom: 180px;
		}
	}

	&--intro {
		@include bp(lg) {
			padding-right: 40%;
			padding-bottom: 30px;
		}

		h2 {
			margin-bottom: 34px;
		}

		p {
			font-size: 16px;

			@include bp(lg) {
				font-size: 20px;
			}
		}
	}

	&--member {
		height: 373px;
		position: relative;
		margin-bottom: 0px;
		border-top-left-radius: 40px;
		overflow: hidden;

		@include bp(lg) {
			margin-bottom: 30px;
		}

		@include bp(lg) {
			margin-bottom: 50px;
			border-top-left-radius: 60px;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		&-content {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: flex-end;
			align-items: flex-start;
			flex-direction: column;
			padding: 20px 27px;
			background: rgb(32,34,38);
			background: linear-gradient(0deg, rgba(32,34,38,1) 0%, rgba(99,101,104,0.09) 50%, rgba(255,255,255,0) 100%);

			h3 {
				font-size: 24px;
				color: $color-white;
				margin-bottom: 0;
			}
			p {
				margin: 0;
				font-size: 14px;
				color: $color-white;
			}
		}
	}

	.columns--four {
		@include bp(lg) {
			justify-content: flex-start;
		}

		.col {
			@include bp(lg) {
				flex: 0 1 calc(50% - 30px);
				margin-right: 30px;
			}
			@include bp(lg) {
				flex: 0 1 calc(25% - 50px);
				margin-right: 50px;
			}
		}
	}

	.flourish.flourish--large.flourish--align-br {
		@include bp(lg) {
			bottom: 9px;
			right: 115px;
			width: 146px;
			height: 146px;
			border-radius: 0 50px;
		}

		&:after {
			@include bp(lg) {
				width: 84px;
				height: 84px;
				right: -94px;
				bottom: 0;
				position: absolute;
				content: "";
				background-color: $color-red;
				border-radius: 30px 0;
			}
		}
	}

}