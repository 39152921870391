.press {
    padding: 0 0 50px 0;
    position: relative;
    z-index: 30;
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        //bottom: -35px;
        //right: -12px;
        background-image: url("img/bottom-dec.svg");
        background-size: cover;
        width: 100%;
        height: 397px;
        top: -185px;
        transform: rotate(180deg);
    }

    &:after {
        content: '';
        position: absolute;
        //bottom: -35px;
        //right: -12px;
        background-image: url("img/bottom-dec.svg");
        background-size: cover;
        width: 100%;
        height: 397px;
        bottom: -215px;
    }

    &--inner {
        position: relative;
        color: $color-white;
        text-align: center;
        padding-top: 135px;
        margin-top: -90px;
        background-image: url(img/bg-press.svg);
        background-position: left -10vw top 0;
        background-size: 120vw auto;
        background-repeat: no-repeat;

        @include bp(lg) {
            background-image: none;
            padding-top: 210px;
            padding-bottom: 93px;
            margin-top: 0;
        }
    }

    .container {
        position: relative;
        z-index: 10;

        @include bp(lg) {
            max-width: 1260px;
        }
    }

    h2 {
        color: $color-black;
        margin: -5px 0 10px 0;
        text-transform: uppercase;
        padding: 0 7px;

        @include bp(lg) {
            letter-spacing: 1.5px;
            margin: -5px 0 60px 0;
            font-size: 40px;
        }
    }

    &--content {

    }

    &--mark {
        @include bp(lg) {
            margin-left: -30px;
        }
    }

    .slick {
        &.press--pager {
            .slick-dots,
            .slick-arrow {
                display: none !important;

                @include bp(lg) {
                    display: flex !important;
                }
            }
            .slick-dots {
                @include bp(lg) {
                    display: none !important;
                }
            }
        }

        &.press--quotes {
            .slick-dots,
            .slick-arrow {
                @include bp(lg) {
                    display: none !important;
                }
            }
            .slick-dots {
                @include bp(lg) {
                    display: flex !important;
                }
            }
            .slide {
                padding: 0 0 36px 0;

                @include bp(lg) {
                    padding: 70px 175px 27px 225px;
                }
            }
        }
    }

    .slide {
        text-align: center;
        padding: 20px 20px 0 20px;

        @include bp(lg) {
            padding: 40px 20px;
        }

        &--indicator {
            width: 70px;
            height: 70px;
            background-color: $color-blue;
            display: inline-flex;
            color: white;
            font-size: 45px;
            justify-content: center;
            align-items: center;
            border-radius: 20px 0 20px 0;
            margin-bottom: 30px;
        }

        img {
            margin: auto;
            width: auto;
            height: 70px;

            @include bp(lg) {
                height: 45px;
            }
        }

        h2 {
            color: $color-black;

            @include bp(lg) {
                font-size: 24px;
                font-weight: normal;
                margin-bottom: 27px;
            }
        }

        h3 {
            color: $color-black;
            font-size: 18px;
            line-height: 1.35;
            margin-top: 35px;
            margin-bottom: 15px;
            padding: 0 3px;

            @include bp(lg) {
                font-size: 36px;
                padding: 0;
                margin: 0;
                line-height: 1.25;
            }
        }

        p {
            color: $color-black;
            padding: 0 4px;
            font-size: 12px;

            @include bp(lg) {
                font-size: 20px;
                letter-spacing: -0.5px;
                margin: 20px 0 0 0;
            }
        }

        .read-more {
            color: white;
            text-decoration: none;
        }

        &.slick-current {
            @include bp(lg) {
                padding: 0 20px;
            }

            img {
                @include bp(lg) {
                    max-width: 260px;
                    height: 125px;
                }
            }
        }
    }

    .next-arrow,
    .prev-arrow {
        width: 50px;
        height: 50px;
        background-color: $color-gold;
        border: 2px solid $color-gold;
        border-radius: 0;
        transform: none;
        cursor: pointer;
        position: absolute;
        top: auto;
        right: 0;
        bottom: 0;
        z-index: 30;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.5s;
        content: 'data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="15.943" height="27.643" viewBox="0 0 15.943 27.643"><path d="M-1827.615-8036.021l12.76,12.761-12.76,12.761" transform="translate(1828.675 8037.082)" fill="none" stroke="#fff" stroke-width="3"/></svg>';

        @include bp(sm) {
            bottom: 0;
        }

        @include bp(lg) {
            width: 66px;
            height: 66px;
            bottom: 32px;
            right: 20px;
        }

        &:hover {
            background-color: $color-green;
            border: 2px solid $color-green;
        }

        svg {
            position: relative;
            right: -2px;
            width: 11px;
            height: 19px;

            @include bp(lg) {
                width: 16px;
                height: 28px;
            }

            path {
                @include bp(lg) {
                    stroke: $color-black;
                }
            }
        }

        &.slick-disabled {
            opacity: 0.75;
        }
    }

    .prev-arrow {
        left: 0;

        @include bp(lg) {
            left: 0;
            right: auto;
            bottom: 32px;
        }

        svg {
            position: relative;
            right: 0;
            left: -2px;
            transform: rotate(180deg);
        }
    }

    .slick-dots {
        display: flex;
        padding: 0;
        margin: 0;
        height: 50px;
        padding-left: 60px;
        padding-right: 60px;
        justify-content: center;
        align-items: center;
        margin-top: -12px;

        @include bp(lg) {
            height: 66px;
            align-items: center;
        }

        li {
            list-style: none;
            margin: 0 7.5px;
            width: 10px;
            height: 10px;
            overflow: hidden;

            @include bp(lg) {
                width: 17px;
                height: 17px;
                margin: 0 26px 0 0;
            }

            &.slick-active {
                button {
                    background-color: $color-gold;
                    opacity: 1;

                    @include bp(lg) {
                        background-color: $color-gold;
                    }
                }
            }

            &:last-child {
                margin-right: 0;
            }
        }

        button {
            width: 10px;
            height: 10px;
            border-radius: 0;
            border: 0;
            font-size: 0;
            display: block;
            opacity: 0.4;
            cursor: pointer;

            @include bp(lg) {
                width: 17px;
                height: 17px;
                background-color: #343434;
                opacity: 1;
            }
        }
    }
}