/*!
Theme Name: orangegrove-base
Theme URI: http://underscores.me/
Author: Orange Grove
Author URI: https://www.orangegrovedesigns.co.uk/
Description: Orange Grove Base Theme
Version: 1.0.0
Tested up to: 5.4
Requires PHP: 5.6
License: GNU General Public License v2 or later
License URI: LICENSE
Text Domain: orangegrove-base
Tags: custom-background, custom-logo, custom-menu, featured-images, threaded-comments, translation-ready

This theme, like WordPress, is licensed under the GPL.
Use it to make something cool, have fun, and share what you've learned.

orangegrove-base is based on Underscores https://underscores.me/, (C) 2012-2020 Automattic, Inc.
Underscores is distributed under the terms of the GNU GPL v2 or later.

Normalizing styles have been helped along thanks to the fine work of
Nicolas Gallagher and Jonathan Neal https://necolas.github.io/normalize.css/
*/

/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Generic
	- Normalize
	- Box sizing
# Base
	- Typography
	- Elements
	- Links
	- Forms
## Layouts
# Components
	- Navigation
	- Posts and pages
	- Comments
	- Widgets
	- Media
	- Captions
	- Galleries
# plugins
	- Jetpack infinite scroll
# Utilities
	- Accessibility
	- Alignments

--------------------------------------------------------------*/

// Import variables and mixins.
@import "abstracts/abstracts";

// Vendors
@import "vendors/datepicker";
@import "vendors/slick";

/*--------------------------------------------------------------
# Generic
--------------------------------------------------------------*/

/* Normalize
--------------------------------------------- */
@import "generic/normalize";

/* Box sizing
--------------------------------------------- */
@import "generic/box-sizing";

/*--------------------------------------------------------------
# Base
--------------------------------------------------------------*/
@import "base/base";

/*--------------------------------------------------------------
# Layouts
--------------------------------------------------------------*/
// @import "layouts/content-sidebar"; // Uncomment this line for a sidebar on right side of your content.
// @import "layouts/sidebar-content"; // Uncomment this line for a sidebar on left side of your content.

/*--------------------------------------------------------------
# Components
--------------------------------------------------------------*/
@import "components/components";

/*--------------------------------------------------------------
# Plugins
--------------------------------------------------------------*/

/* Jetpack infinite scroll
--------------------------------------------- */
@import "plugins/jetpack/infinite-scroll";

/*--------------------------------------------------------------
# Utilities
--------------------------------------------------------------*/

/* Accessibility
--------------------------------------------- */
@import "utilities/accessibility";

/* Alignments
--------------------------------------------- */
@import "utilities/alignments";

/* Debug
--------------------------------------------- */
@import "utilities/debug";

/* Selective elements
--------------------------------------------- */
@import "utilities/selective";