.enquiry-callout {
	@include bp(lg) {
		padding-top: 74px;
	}

	&--inner {
		@include bp(lg) {
			padding-bottom: 32px;
			border-bottom: 2px solid $color-red;
		}
	}

	.columns--two {
		.col {
			&:first-child {
				@include bp(lg) {
					flex: 0 1 55%;
				}
				@include bp(lg) {
					flex: 0 1 62%;
				}
			}
			&:last-child {
				@include bp(lg) {
					flex: 0 1 45%;
					margin-left: auto;
				}
				@include bp(lg) {
					flex: 0 1 28%;
				}
			}
		}
	}

	&--intro {
		h2 {
			margin-bottom: 21px;
		}
		p {
			@include bp(lg) {
				padding-right: 200px;
			}
		}
	}

	&--team {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;

		@include bp(xs) {
			flex-direction: row-reverse;
		}

		p {
			margin-top: 0;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.btn {
			border-radius: 15px;
		}
	}

	&--image {
		height: 60px;
		float: right;
		display: inline-block;
		border: 2px solid $color-red;
		border-radius: 50%;
		padding: 5px;
		overflow: hidden;
		flex: 0 1 60px;

		@include bp(xs) {
			height: 155px;
			flex: 0 1 155px;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 50%;
		}
	}

	&--content {
		flex: 0 1 calc(100% - 60px);
		padding-left: 20px;

		@include bp(xs) {
			padding-left: 0;
			flex: 0 1 calc(100% - 155px)
		}
	}

	&--subpage {
		background-color: $color-grey;

		@include bp(lg) {
			padding-top: 15px;
		}

		.enquiry-callout--inner {
			@include bp(lg) {
				padding: 60px 0 50px 0;
				border-bottom: 0;
				border-top: 2px solid $color-red;
			}
		}
	}
}