.feature-text {
	font-family: $font--togalite-bold, serif;
	color: transparent;
	text-shadow: 0 0 25px $color-red-keyline;
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: $color-red-keyline;
	letter-spacing: 1px;
	font-size: 20px;
	white-space: nowrap;

	@include bp(lg) {
		-webkit-text-stroke-width: 3px;
		font-size: 80px;
		letter-spacing: 8px;
	}

	.flicker-slow{
		animation: flicker 3s linear infinite;
	}

	.flicker-fast{
		animation: flicker 1s linear infinite;
	}
}

@keyframes flicker {
	0%, 19.999%, 22%, 62.999%, 64%, 64.999%, 70%, 100% {
		//text-shadow: none;
		opacity: .99;

	}
	20%, 21.999%, 63%, 63.999%, 65%, 69.999% {
		opacity: 0.4;
	}
}