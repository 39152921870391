.our-vision {
	background-color: $color-grey;

	@include bp(lg) {
		margin-top: 105px;
	}

	h2 {
		margin-bottom: 0px;

		@include bp(lg) {
			margin-bottom: 30px;
		}
	}

	.columns--two {
		.col {
			padding-bottom: 0;

			@include bp(lg) {
				padding-bottom: 30px;
				flex: 0 1 48.75%;
			}
		}
	}
}