.site-footer  {
    padding: 30px 0 70px 0;

    @include bp(lg) {
        padding: 65px 0 0 0;
        border-top: 9px solid $color-gold-dark;
    }

    .container {
        @include bp(lg) {
            display: flex;
        }
    }

    .footer-main {
        border-bottom: 1px solid $color-gold-dark;

        .gform_ajax_spinner {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        .gfield_validation_message {
            position: absolute;
            margin-top: 5px;
        }

        @include bp(lg) {
            padding-bottom: 26px;

        }

        .gform_wrapper {
            &.gform_validation_error {
                padding-bottom: 15px;

                @include bp(lg-max) {
                    text-align: left;
                    margin-right: 0;

                    .gform_body .gfield:nth-child(2) {
                        margin-top: 35px;
                    }
                }

                .gform_footer {
                    margin-top: 45px;
                }
            }

            form {
                display: flex;
                align-items: flex-end;
                color: $color-black;

                @media screen and (max-width: 1124px) {
                    flex-direction: column;
                }
            }

            .gform_body {
                padding: 0;

                @media screen and (max-width: 1124px) {
                    width: 100%;
                }

                .gform_fields.top_label {
                    display: flex;

                    @include bp(lg-max) {
                        flex-direction: column;
                    }
                }

                input {
                    height: 52px;
                }

                .gfield {
                    margin-right: 30px;
                    flex-grow: 1;

                    @include bp(lg-max) {
                        text-align: left;
                        margin-right: 0;
                    }

                    &:nth-child(2) {
                        @media screen and (max-width: 1124px) {
                            margin-right: 0;
                        }

                        @include bp(lg-max) {
                            margin-top: 15px;
                        }
                    }
                }
            }

            .gform_footer {
                padding: 0;
                position: relative;

                @media screen and (max-width: 1124px) {
                    margin-top: 20px;
                    width: 100%;
                }

                input {
                    padding-left: 20px;
                    padding-right: 20px;
                    height: 52px;
                    line-height: 1;
                }
            }
        }
    }

    h2 {
        color: $color-gold-dark;
        margin: 0 0 10px 0;
        font-size: 20px;

        @include bp(lg) {
            font-size: 24px;
            margin: 0 0 20px 0;
        }
    }

    p {
        color: $color-black;
        margin-top: 0;
        margin-bottom: 0;
        font-size: 15px;
        line-height: 1.75;

        a {
            color: $color-black;
            text-decoration: none;
            transition: color 0.5s;

            &:hover {
                color: $color-gold;
            }
        }
    }

    .footer-column {
        padding-bottom: 50px;
        box-sizing: border-box;
        text-align: center;

        @include bp(lg) {
            padding-bottom: 0;
            padding-right: 30px;
            text-align: left;
        }

        &:nth-child(1) {
            @include bp(lg) {
                flex: 0 1 23.25%;
                padding-right: 30px;
                margin-top: -15px;
                margin-left: -20px;
                flex-shrink: 0;
            }

            //@include bp(xlg) {
            //    padding-right: 125px;
            //}

            svg {
                width: 166px;
                height: 130px;

                @include bp(xlg) {
                    width: 201px;
                    height: 157px;
                }
            }
        }

        &:nth-child(2) {
            @include bp(lg) {
                flex: 0 1 22.15%;
            }
        }

        &:nth-child(3) {
            @include bp(lg) {
                flex: 0 1 24.25%;
            }
        }

        &:nth-child(4) {
            @include bp(lg) {
                flex: 0 1 53.35%;
                padding-right: 0;
            }
        }

        &:nth-child(5) {
            padding-bottom: 25px;

            img {
                max-width: 200px;
            }
        }
    }

    .footer-column--contact {
        margin: 0 0 35px 0;

        @include bp(lg) {
            margin: 0 0 27px 0;
        }
    }

    .social-container {
        display: flex;
        align-items: center;
        justify-content: center;

        @include bp(lg) {
            justify-content: flex-start;
        }

        a {
            margin: 0 7.5px;
            transition: background-color 0.5s;

            @include bp(lg) {
                margin: 0 15px 0 0;
            }

            &:hover {
                background-color: $color-white;
            }
        }
    }

    .menu {
        padding: 0;
        margin: 0 0 27px 0;

        &#menu-footer-contact {
            margin: 0;
        }

        li {
            list-style: none;
            margin-top: 0;
            margin-bottom: 0;
            font-size: 15px;
            line-height: 1.75;

            a {
                color: $color-black;
                text-decoration: none;
                transition: color 0.5s;

                &:hover {
                    color: $color-gold;
                }
            }
        }
    }

    .footer-column--subscribe {
        @include bp(lg-max) {
            max-width: 450px;
            margin: auto;
        }

        p {
            max-width: 450px;
            margin-bottom: 30px;
        }

        .btn {
            @include bp(lg) {
                width: auto;
            }
        }
    }

    .copyright {
        padding: 34px 0 14px 0;
        font-size: 14px;
        text-align: center;
        color: $color-black;

        @include bp(lg) {
            padding: 34px 0;
            font-size: 15px;
            text-align: left;
        }

        .container {
            @include bp(lg) {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }

        &-column {
            margin: 0 0 32px 0;

            .menu-copyright-menu-links-container {
                @include bp(lg) {
                    margin-left: 15px;
                    padding-left: 15px;
                    border-left: 1px solid $color-black;
                }
            }

            @include bp(lg) {
                display: flex;
                margin: 0;
                flex-direction: row;
            }

            .menu {
                margin: 0;
                display: flex;
                padding-top: 15px;
                justify-content: center;

                @include bp(lg) {
                    padding-top: 0;
                    justify-content: flex-start;
                }

                li {
                    line-height: 1;
                    font-size: 14px;

                    @include bp(lg) {
                        font-size: 15px;
                    }

                    a {
                        color: $color-black;
                        border-right: 1px solid $color-black;
                        margin-right: 10px;
                        display: inline-block;
                        padding-right: 10px;

                        @include bp(lg) {
                            margin-right: 15px;
                            padding-right: 15px;
                        }
                    }

                    &:last-child {
                        a {
                            color: $color-black;
                            border-right: 0;
                            margin-right: 0;
                            padding-right: 0;
                        }
                    }
                }
            }
        }

        span {
            display: none;

            @include bp(lg) {
                display: inline-block;
                margin: 0 15px;
            }
        }

        a {
            color: $color-black;
            text-decoration: none;
            transition: color 0.5s;

            &:hover {
                color: #ee7818;
            }
        }
    }
}
