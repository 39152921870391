.services {

	&--inner {
		position: relative;

		@include bp(lg) {
			padding-top: 105px;
		}
	}

	@include bp(lg) {
		padding-bottom: 140px;
	}

	.flourish {
		display: none;

		@include bp(lg) {
			display: block;
		}
	}

	.container {
		position: static;

		&:after {
			@include bp(lg) {
				content: "";
				width: calc(100% - 58px);
				height: 100%;
				position: absolute;
				top: 0;
				right: 58px;
				background-color: $color-grey;
				z-index: 0;
				border-top-right-radius: 60px;
			}
		}
	}

	&--intro {
		position: relative;
		z-index: 1;

		@include bp(lg) {
			margin-bottom: 55px;
		}

		h2 {
			line-height: 1.5;
		}

		.btn {
			@include bp(lg) {
				float: right;
				display: inline-block;
				margin-top: 20px;
			}
		}

		> .col {
			&:nth-child(2) {
				display: none;

				@include bp(lg) {
					display: block;
				}
			}
		}
	}

	&--list {
		position: relative;
		z-index: 1;

		@include bp(lg) {
			padding-bottom: 110px;
		}
	}

	&--image {
		height: 200px;
		margin-bottom: 20px;
		border-top-left-radius: 30px;
		border-bottom-right-radius: 30px;
		overflow: hidden;

		@include bp(sm) {
			height: 250px;
			margin-bottom: 30px;
		}

		@include bp(lg) {
			height: 325px;
			margin-bottom: 35px;
			border-top-left-radius: 60px;
			border-bottom-right-radius: 60px;
			overflow: hidden;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&--content {
		h3 {
			font-size: 20px;
			margin-bottom: 27px;
		}
		p {
			line-height: 1.5;
			margin-bottom: 38px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&--subpage {

		.container {
			position: relative;

			&:after {
				@include bp(lg) {
					display: none;
				}
			}
		}

		.services--inner {
			@include bp(lg) {
				padding-top: 0;
			}
		}

		.services--intro {
			position: relative;

			.flourish.flourish--medium.flourish--align-tr {
				@include bp(lg) {
					width: 104px;
					height: 104px;
					top: 85px;
					right: 15px;
					border-radius: 0 40px;
				}

				&:after {

				}
			}
		}

		.services--list {
			@include bp(lg) {
				padding-bottom: 0;
			}
		}

		.services--image {
			height: 200px;
			margin-bottom: 20px;
			border-top-left-radius: 30px;
			border-bottom-right-radius: 30px;
			overflow: hidden;

			@include bp(sm) {
				height: 250px;
				margin-bottom: 30px;
				border-top-left-radius: 40px;
				border-bottom-right-radius: 40px;
			}

			@include bp(lg) {
				height: 450px;
				margin-bottom: 50px;

			}
		}

		.columns--two {
			.col {
				@include bp(lg) {
					flex: 0 1 50%;
				}
			}
		}

		@include bp(lg) {
			padding-bottom: 0;
		}
	}

	&--products {
		.services--inner {
			position: relative;

			@include bp(lg) {
				padding-top: 70px;
			}
		}

		.services--intro {
			@include bp(lg) {
				margin-bottom: 30px;
			}
		}

		.services--list {
			.col {
				padding-bottom: 0;
			}
		}

		.services--product-item {
			position: relative;
			margin-bottom: 30px;

			@include bp(lg) {
				height: 455px;
			}

			&:hover {
				img {
					transform: scale(1.1);
				}
			}
		}

		.services--image {
			height: 250px;
			position: relative;
			overflow: hidden;
			border-radius: 40px 0 40px 0;

			&:after {
				content: "";
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				display: block;
				z-index: 5;
				background-color: rgba(0, 0, 0, 0.6);
				pointer-events: none;
			}

			@include bp(lg) {
				height: 325px;
				margin-bottom: 0;

			}
			@include bp(lg) {
				height: 455px;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				transition: transform 0.5s;
			}
		}

		.services--content {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			padding: 0 33px 33px 33px;
			z-index: 6;

			h3 {
				margin-bottom: 7px;
				color: $color-white;
			}
		}

		.container {
			&:after {
				@include bp(lg) {
					right: 25vw;
					width: calc(100% - 25vw);
				}
			}
		}

		.columns--two {
			.col {
				&:nth-child(1) {
					@include bp(lg) {
						flex: 0 1 57.5%;
					}
				}
				&:nth-child(2) {
					@include bp(lg) {
						flex: 0 1 40.25%;
					}
				}
				&:nth-child(3) {
					@include bp(lg) {
						flex: 0 1 40.25%;
					}
				}
				&:nth-child(4) {
					@include bp(lg) {
						flex: 0 1 57.5%;
					}
				}
			}
		}
	}
}