.mobile-only {
	@include bp(lg) {
		display: none;
	}
}

.desktop-only {
	display: none;

	@include bp(lg) {
		display: block;
	}
}