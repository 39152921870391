.restaurants {
	padding-top: 0;
	//padding-bottom: 110px;
	position: relative;
	z-index: 10;

	&--inner {
		padding-top: 50px;
		position: relative;

		.container {
			z-index: 20;
			position: relative;

			@include bp(lg) {
				width: 95%;
			}
		}
	}

	.feature-smudge {
		@include bp(lg) {
			margin-left: -20px;
		}
	}

	&--intro {
		.feature-smudge {
			margin-left: auto;
			margin-right: auto;

			svg {
				left: 35px;
			}

			span {
				margin-left: 0;
			}

			@include bp(lg) {
				margin-left: 0;

				svg {
					left: auto;
				}

				span {
					margin-left: -125px;
				}
			}
		}

		.col {
			padding-bottom: 13px;
		}

		h2 {
			color: $color-black;
			text-transform: uppercase;
			text-align: center;
			margin-top: 5px;

			@include bp(lg) {
				text-align: left;
				font-size: 42px;
				margin-top: 0;
			}
		}
	}

	&--list {
		@include bp(lg) {
			max-width: 1250px;
			margin: auto;
			width: 90%;
		}

		> .col {
			@include bp(lg) {
				padding-left: 20px;
				padding-right: 20px;
			}

			&:nth-child(2) {
				@include bp(lg) {
					padding-top: 58px;
					margin-top: -18px;
				}
			}

			&:nth-child(3) {
				@include bp(lg) {
					padding-top: 116px;
					margin-top: -36px;
				}
			}
		}
	}

	&--image {
		img {
			height: 250px;
			width: 100%;
			object-fit: cover;

			@include bp(lg) {
				height: 350px;
			}
		}
	}

	&--content {
		margin-bottom: 40px;

		h3 {
			color: $color-black;
			margin-top: 10px;
			margin-bottom: 15px;

			@include bp(lg) {
				font-size: 36px;
				margin-top: 25px;
			}
		}
		p {
			color: $color-black;
			display: none;

			@include bp(lg) {
				display: block;
				font-size: 16px;
			}

			&:last-of-type {
				@include bp(lg) {
					margin-bottom: 45px;
				}
			}
		}
		.btn {
			width: auto;
			display: inline-block;
			padding: 0 54px;

			//&:visited {
			//	color: $color-black;
			//
			//	&:hover {
			//		color: $color-white;
			//	}
			//}

			@include bp(lg) {
				line-height: 60px;
				padding: 0 48px;
			}
		}
	}
}
.template-single {
	.restaurants {
		padding-bottom: 30px;

		&:before {
			content: '';
			position: absolute;
			background-image: url("img/bottom-dec.svg");
			background-size: cover;
			width: 100%;
			height: 397px;
			top: -169px;
			left: 0;
			z-index: 1;
		}

		&--intro {
			text-align: center;

			//@include bp(lg) {
			//	display: none;
			//}
		}

		&--inner {
			padding-top: 30px;

			@include bp(lg) {
				padding-top: 30px;
			}

			.container {
				@include bp(lg) {
					//max-width: 970px;
					padding: 0 20px;
				}
			}
		}

		&--content {
			h3 {
				@include bp(lg) {
					margin-top: 42px;
				}
			}
			p {
				&:last-of-type {
					@include bp(lg) {
						margin-bottom: 30px;
					}
				}
			}
			//.btn {
			//	@include bp(lg) {
			//		background-color: $color-blue;
			//		color: white;
			//	}
			//}
		}

		//&--image {
		//	img {
		//		@include bp(lg) {
		//			height: 250px;
		//		}
		//	}
		//}
	}
}