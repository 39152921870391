ul,
ol {
	margin: 0 0 1.5em 3em;
}

ul {
	list-style: disc;
}

ol {
	list-style: decimal;
}

li > ul,
li > ol {
	margin-bottom: 0;
	margin-left: 1.5em;
}

dt {
	font-weight: 700;
}

dd {
	margin: 0 1.5em 1.5em;
}

nav.dropdown {
	position: relative;
	z-index: 5;

	> ul {
		> li {
			border: 2px solid $color-black;
			border-radius: 15px;
			overflow: hidden;
			background-color: white;

			> a {
				position: relative;

				&:before {
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 1px;
					border-top: 2px solid $color-grey;
					content: "";
					display: block;
					z-index: 50;
				}
			}

			&:hover {
				border-radius: 15px 15px 0 0;
			}
		}
	}

	li {
		display: flex;

		&:hover {
			cursor: pointer;
		}

		a {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 17px 21px;
			color: $color-black;
			text-decoration: none;
			flex: 0 1 100%;

			svg {
				transition: transform 0.5s ease-in-out;
			}

			&:hover {
				svg {
					transform: rotate(180deg);
				}
			}
		}

		ul {
			width: 100%;
			visibility: hidden;
			opacity: 0;
			position: absolute;
			transition: all 0.5s ease;
			margin-top: 0;
			left: 0;
			display: none;
			top: 52px;
			background-color: white;
			border-left: 2px solid $color-black;
			border-bottom: 2px solid $color-black;
			border-right: 2px solid $color-black;
			z-index: 5;
			border-radius: 0 0 15px 15px;

			li {
				clear: both;
				width: 100%;

				a {
					transition: color 0.5s;

					&:hover {
						color: $color-red;
					}
				}
			}
		}

		&:hover > ul,
		ul:hover {
			visibility: visible;
			opacity: 1;
			display: block;
		}
	}
}