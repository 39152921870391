.post-quote {
	padding-top: 60px;
	padding-bottom: 60px;
	position: relative;
	z-index: 10;
	background-color: $color-white;

	@include bp(lg) {
		padding-top: 95px;
		max-width: 1050px;
		margin: auto;
	}

	.container {
		@include bp(lg) {
			max-width: 970px;
		}
	}

	//.feature-text {
	//	position: absolute;
	//	top: -10px;
	//	right: 20px;
	//
	//	@include bp(lg) {
	//		position: absolute;
	//		transform: rotate(90deg);
	//		transform-origin: top right;
	//		display: block;
	//		bottom: auto;
	//		top: 490px;
	//		right: auto;
	//		left: -390px;
	//		z-index: 20;
	//	}
	//}

	&--inner {
		background-color: $color-blue;
		position: relative;
		padding: 22px 20px;
		color: $color-white;

		@include bp(lg) {
			padding: 35px 90px;

			&:before {
				content: url('img/lef-border-small.svg');
				left: -30px;
				position: absolute;
				top: -25px;
			}

			&:after {
				content: url('img/right-border-bottom-small.svg');
				position: absolute;
				bottom: -35px;
				right: -30px;
			}
		}
	}

	h2 {
		color: $color-white;
		margin-bottom: 13px;
		line-height: 1.35;
		font-size: 18px;

		@include bp(lg) {
			font-size: 36px;
			line-height: 1.25;
		}
	}

	&--author {
		padding-top: 0;

		@include bp(lg) {
			padding: 13px 0;
		}

		p {
			margin-top: 0;
			font-size: 12px;

			@include bp(lg) {
				font-size: 20px;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		.btn {
			border-radius: 15px;
		}
	}

	//&--art {
	//	pointer-events: none;
	//	position: absolute;
	//	top: -80px;
	//	right: -50px;
	//
	//	@include bp(lg) {
	//		top: -45px;
	//		right: auto;
	//		left: -118px;
	//	}
	//}
	//
	//&--drip {
	//	text-align: right;
	//
	//	svg {
	//		@include bp(lg) {
	//			transform: scale(1.75);
	//			position: relative;
	//			right: 44px;
	//			bottom: -23px;
	//		}
	//	}
	//}
}