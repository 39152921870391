.columns {
	margin-left: -15px;
	margin-right: -15px;

	@include bp(lg) {
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}

	> .col {
		padding-left: 15px;
		padding-right: 15px;
		padding-bottom: 20px;
		width: 100%;
	}

	&--one {
		> .col {
			@include bp(lg) {
				flex: 0 1 100%;
			}
		}
	}

	&--two {
		flex-wrap: wrap;

		> .col {
			@include bp(lg) {
				flex: 0 1 50%;
				width: 50%;
			}
		}

		&-sidebar {
			> .col {
				&:first-child {
					@include bp(lg) {
						flex: 0 1 33.3333%;
					}
				}
				&:last-child {
					@include bp(lg) {
						flex: 0 1 57.75%;
						margin-left: auto;
					}
				}
			}
		}
	}

	&--three {
		> .col {
			@include bp(lg) {
				flex: 0 1 33.333%;
			}
		}
	}
	&--four {
		> .col {
			@include bp(sm) {
				flex: 0 1 50%;
			}
			@include bp(lg) {
				flex: 0 1 25%;
			}
		}
	}
	&--five {
		> .col {
			@include bp(lg) {
				flex: 0 1 20%;
			}
		}
	}
}