.video {
	padding-top: 0;

	&--content {
		h2 {
			margin-bottom: 27px;
		}
	}

	&--duration {
		width: 100%;
		position: relative;
		max-width: 100%;
		height: auto;
		text-align: left;

		@include bp(lg) {
			max-width: 860px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 48px;
		}

		span {
			color: $color-red;
		}
	}

	&--primary {
		max-width: 890px;
		margin-left: 20px;
		margin-right: 20px;
		position: relative;
		z-index: 15;
		text-align: center;
		margin-top: -60px;

		@include bp(xs) {
			margin-left: 50px;
			margin-right: 50px;
		}

		@include bp(lg) {
			margin-left: auto;
			margin-right: auto;
			margin-top: -135px;
		}

		.video--content {
			p {
				line-height: 1.5;
				max-width: 750px;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}

	&--secondary {
		text-align: center;
		position: relative;
		margin-left: 20px;
		margin-right: 20px;
		padding-top: 60px;

		@include bp(xs) {
			margin-left: 50px;
			margin-right: 50px;
		}

		@include bp(lg) {
			margin-left: 0;
			margin-right: 0;
			padding-top: 112px;
		}

		h2 {
			margin-bottom: 30px;

			@include bp(lg) {
				margin-bottom: 65px;
			}
		}

		.video--embed {
			position: relative;

			@include bp(lg) {
				padding-bottom: 0;
				height: 492px;
				margin-bottom: 15px;
				overflow: visible;
			}

			.flourish--medium.flourish--align-br.flourish--sub-d {
				@include bp(lg) {
					width: 103px;
					height: 103px;
					bottom: 0;
					right: -113px;
				}

				&:after {
					@include bp(lg) {
						content: "";
						width: 71px;
						height: 71px;
						background-color: $color-red;
						display: block;
						position: absolute;
						bottom: -80px;
						left: 0;
						border-radius: 0 20px 0 20px;
					}
				}
			}
		}
	}
	&--tertiary {
		@include bp(lg) {
			padding-top: 138px;
		}

		.container {
			@include bp(lg) {
				position: relative;
				padding-bottom: 117px;
			}
		}

		.columns--two {
			.col {
				&:nth-child(1) {
					@include bp(lg) {
						flex: 0 1 35%;
					}
				}
				&:nth-child(2) {
					@include bp(lg) {
						flex: 0 1 60.75%;
					}
				}
			}
		}

		.video--intro {
			h2 {
				margin-top: 0;
				line-height: 1.5;
			}
		}

		.video--embed {
			@include bp(lg) {
				margin-bottom: 30px;
			}
		}

		.flourish.flourish--medium.flourish--align-bl.flourish--sub-r {
			display: none;

			@include bp(lg) {
				width: 124px;
				height: 124px;
				bottom: 10px;
				left: 33px;
				border-radius: 0 40px 0 40px;
				display: block;
			}

			&:before {
				@include bp(lg) {
					width: 89px;
					height: 89px;
					right: -97px;
					border-radius: 30px 0 30px 0;
				}
			}
		}
	}
}
.single-case_study {
	.video {
		.flourish {
			display: none !important;
		}
	}
}
.template-case-study {
	.video {
		.container {
			padding-bottom: 0;
		}
		.columns--two {
			@include bp(lg) {
				align-items: center;
			}
		}
		.flourish {
			display: none;
		}
	}
}