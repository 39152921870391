/* ------------------ example styles ------------------ */
.relative { position: relative; }
.mod--hidden { overflow: hidden; }
.pb1 { padding-bottom: 1em; }

.news-carrousel__container {
	white-space: nowrap;
}
.news-carrousel__control__list {
	position: absolute;
	list-style-type: none;
	top: 100%;
	right: 0;
	left: 0;
	margin-top: -0.5em;
	padding-left: 0;
	text-align: center;
}
.news-carrousel__control__list__item {
	display: inline-block;
	margin: 0 .5em;
}
.news-carrousel__control__list__link {
	display: inline-block;
	width: 1em;
	height: 1em;
	background: #fff;
	border: 1px solid #148297;
	border-radius: 1em;
	cursor: pointer;
}

.news-carrousel__button-container {
	position: absolute;
}
.news-carrousel__button__previous {
	top: 50%;
	margin-top: -18px;
	left: 0;
	margin-left: -35px;
}
.news-carrousel__button__next {
	top: 50%;
	margin-top: -18px;
	right: 0;
	margin-right: -35px;
}
.news-carrousel__button__button {
	margin: 0;
	padding: 0;
	background: transparent;
	border: 0;
	cursor: pointer;
}

/* ------------------ transition slide ------------------ */
.slide .carrousel__content {
	display: inline-block;
	vertical-align: top;
	visibility: visible;
	width: 100%;
	position: relative;
	-webkit-transition: visibility 0s ease, transform .5s ease-in;
	transition: visibility 0s ease, transform .5s ease-in;
	-webkit-transition-delay: 0;
	transition-delay: 0;
	white-space: normal;
}

[data-carrousel-active-slide="1"].slide > .carrousel__content {
	-webkit-transform: translateX(0);
	transform: translateX(0);
}
[data-carrousel-active-slide="2"].slide > .carrousel__content {
	-webkit-transform: translateX(-100%);
	transform: translateX(-100%);
}
[data-carrousel-active-slide="3"].slide > .carrousel__content {
	-webkit-transform: translateX(-200%);
	transform: translateX(-200%);
}
[data-carrousel-active-slide="4"].slide > .carrousel__content {
	-webkit-transform: translateX(-300%);
	transform: translateX(-300%);
}
[data-carrousel-active-slide="5"].slide > .carrousel__content {
	-webkit-transform: translateX(-400%);
	transform: translateX(-400%);
}
[data-carrousel-active-slide="6"].slide > .carrousel__content {
	-webkit-transform: translateX(-500%);
	transform: translateX(-500%);
}
[data-carrousel-active-slide="7"].slide > .carrousel__content {
	-webkit-transform: translateX(-600%);
	transform: translateX(-600%);
}
[data-carrousel-active-slide="8"].slide > .carrousel__content {
	-webkit-transform: translateX(-700%);
	transform: translateX(-700%);
}
[data-carrousel-active-slide="9"].slide > .carrousel__content {
	-webkit-transform: translateX(-800%);
	transform: translateX(-800%);
}

[data-carrousel-active-slide].slide > [aria-hidden="true"].carrousel__content {
	visibility: hidden;
	-webkit-transition-delay: .5s, 0s;
	transition-delay: .5s, 0s;
}

/* ------------------ transition fade ------------------ */
.fade .carrousel__content {
	-webkit-animation: fadein 1s;
	animation:         fadein 1s;
	white-space: normal;
}
.fade .carrousel__content[aria-hidden=true] {
	-webkit-animation: fadeout 1s;
	animation:         fadeout 1s;
}
.fade .carrousel__content[aria-hidden=true] {
	display: none;
}

@-webkit-keyframes fadeout {
	0%   { opacity: 0; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; }
	100% { opacity: 1; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"; }
}
@keyframes fadeout {
	0%   { opacity: 1; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"; }
	100% { opacity: 0; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; }
}

@-webkit-keyframes fadein {
	0%   { opacity: 0; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; }
	100% { opacity: 1; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"; }
}
@keyframes fadein {
	0%   { opacity: 0; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; }
	100% { opacity: 1; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"; }
}

/* ------------------ transition none ------------------ */
.none .carrousel__content {
	white-space: normal;
}
.none .carrousel__content[aria-hidden=true] {
	display: none;
}

/* ------------------ State rules ------------------ */
.news-carrousel__control__list__link:focus,
.news-carrousel__control__list__link:hover,
.news-carrousel__control__list__link:active,
.news-carrousel__control__list__link[aria-selected=true] {
	background: #148297;
}
.news-carrousel__control__list__link:focus,
.news-carrousel__control__list__link:hover,
.news-carrousel__control__list__link:active {
	outline: 2px dotted #4d287f;
}
