//Custom
$color-red: #841C23;
$color-red-dark: #3E0000;
$color-red-keyline: #BE111D;
$color-red-tab: #6E171D;
$color-gold: #CAB289;
$color-gold-dark: #8B724C;
$color-green: #224A5A;
$color-blue: #0B1E3C;
$color-sky: #EDF2F5;
$color-beige: #D2C6B8;
$color-grey: #F7F5F5;
$color-grey-dark: #1A1A1A;
$color-graphite: #232323;
$color-graphite-alternate: #3D3D3D;
$color-black: #000000;
$color-white: #FFF;
$color-purple: #581440;
$color-keyline: #DFE1E5;
$color-wrapper-keyline: #e5e5e5;

$color-vegetarian: #5EA300;
$color-chilli: $color-red-keyline;
$color-nuts: #724F13;

$color__background-body: #000000;
$color__background-screen: white;
$color__background-hr: #ccc;
$color__background-button: #e6e6e6;
$color__background-pre: #eee;
$color__background-ins: #fff9c0;

$color__text-screen: $color-black;
$color__text-input: #666;
$color__text-input-focus: #111;
$color__link: $color-red; //purple
$color__link-visited: $color-red; //purple
$color__link-hover: #191970; //midnightblue
$color__text-main: #404040;

$color__border-button: #ccc #ccc #bbb;
$color__border-button-hover: #ccc #bbb #aaa;
$color__border-button-focus: #aaa #bbb #bbb;
$color__border-input: #ccc;
$color__border-abbr: #666;