.post-intro {
	padding-top: 15px;
	padding-bottom: 1px;
	color: $color-black;
	background-color: $color-white;
	position: relative;
	z-index: 1;

	@include bp(lg) {
		//padding-top: 100px;
		max-width: 1050px;
		margin: auto;
	}

	.container {
		@include bp(lg) {
			max-width: 970px;
		}
	}
}