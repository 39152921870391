@font-face {
	font-family: 'Chromate';
	src:  url('/wp-content/themes/flesh-and-buns/fonts/Chromate-Regular.eot');
	src:  url('/wp-content/themes/flesh-and-buns/fonts/Chromate-Regular.eot?#iefix') format('embedded-opentype'),
	url('/wp-content/themes/flesh-and-buns/fonts/Chromate-Regular.woff') format('woff'),
	url('/wp-content/themes/flesh-and-buns/fonts/Chromate-Regular.ttf') format('truetype'),
	url('/wp-content/themes/flesh-and-buns/fonts/Chromate-Regular.svg#Chromate-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins Regular';
	font-weight: 400;
	src:  local('Poppins'), local('Poppins-Regular');
	src:  url('/wp-content/themes/flesh-and-buns/fonts/Poppins-Regular.eot');
	src:  url('/wp-content/themes/flesh-and-buns/fonts/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
	url('/wp-content/themes/flesh-and-buns/fonts/Poppins-Regular.woff') format('woff'),
	url('/wp-content/themes/flesh-and-buns/fonts/Poppins-Regular.ttf') format('truetype'),
	url('/wp-content/themes/flesh-and-buns/fonts/Poppins-Regular.svg#Poppins-Regular') format('svg');
	font-display: swap;
}

@font-face {
	font-family: 'Poppins Light';
	font-weight: 700;
	src:  local('Poppins'), local('Poppins-Light');
	src:  url('/wp-content/themes/flesh-and-buns/fonts/Poppins-Light.eot');
	src:  url('/wp-content/themes/flesh-and-buns/fonts/Poppins-Light.eot?#iefix') format('embedded-opentype'),
	url('/wp-content/themes/flesh-and-buns/fonts/Poppins-Light.woff') format('woff'),
	url('/wp-content/themes/flesh-and-buns/fonts/Poppins-Light.ttf') format('truetype'),
	url('/wp-content/themes/flesh-and-buns/fonts/Poppins-Light.svg#Poppins-Light') format('svg');
	font-display: swap;
}

@font-face {
	font-family: 'Poppins Medium';
	font-weight: 700;
	src:  local('Poppins'), local('Poppins-Bold');
	src:  url('/wp-content/themes/flesh-and-buns/fonts/Poppins-Medium.eot');
	src:  url('/wp-content/themes/flesh-and-buns/fonts/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
	url('/wp-content/themes/flesh-and-buns/fonts/Poppins-Medium.woff') format('woff'),
	url('/wp-content/themes/flesh-and-buns/fonts/Poppins-Medium.ttf') format('truetype'),
	url('/wp-content/themes/flesh-and-buns/fonts/Poppins-Medium.svg#Poppins-Medium') format('svg');
	font-display: swap;
}

@font-face {
	font-family: 'Poppins Bold';
	font-weight: 700;
	src:  local('Poppins'), local('Poppins-Bold');
	src:  url('/wp-content/themes/flesh-and-buns/fonts/Poppins-Bold.eot');
	src:  url('/wp-content/themes/flesh-and-buns/fonts/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
	url('/wp-content/themes/flesh-and-buns/fonts/Poppins-Bold.woff') format('woff'),
	url('/wp-content/themes/flesh-and-buns/fonts/Poppins-Bold.ttf') format('truetype'),
	url('/wp-content/themes/flesh-and-buns/fonts/Poppins-Bold.svg#Poppins-Bold') format('svg');
	font-display: swap;
}

@font-face {
	font-family: 'Poppins SemiBold';
	src:  local('Poppins'), local('Poppins-Bold');
	src:  url('/wp-content/themes/flesh-and-buns/fonts/Poppins-SemiBold.eot');
	src:  url('/wp-content/themes/flesh-and-buns/fonts/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
	url('/wp-content/themes/flesh-and-buns/fonts/Poppins-SemiBold.woff') format('woff'),
	url('/wp-content/themes/flesh-and-buns/fonts/Poppins-SemiBold.ttf') format('truetype'),
	url('/wp-content/themes/flesh-and-buns/fonts/Poppins-SemiBold.svg#Poppins-SemiBold') format('svg');
	font-display: swap;
}

@font-face {
	font-family: 'Togalite Bold';
	font-weight: 700;
	src:  local('Togalite'), local('Togalite-Bold');
	src:  url('/wp-content/themes/flesh-and-buns/fonts/Togalite-Bold.eot');
	src:  url('/wp-content/themes/flesh-and-buns/fonts/Togalite-Bold.eot?#iefix') format('embedded-opentype'),
	url('/wp-content/themes/flesh-and-buns/fonts/Togalite-Bold.woff') format('woff'),
	url('/wp-content/themes/flesh-and-buns/fonts/Togalite-Bold.ttf') format('truetype'),
	url('/wp-content/themes/flesh-and-buns/fonts/Togalite-Bold.svg#Togalite-Bold') format('svg');
	font-display: swap;
}

$font--chromate: "Chromate", Arial, Helvetica, Sans-Serif;
$font--poppins-light: "Poppins Light", Arial, Helvetica, Sans-Serif;
$font--poppins-regular: "Poppins Regular", Arial, Helvetica, Sans-Serif;
$font--poppins-medium: "Poppins Medium", Arial, Helvetica, Sans-Serif;
$font--poppins-bold: "Poppins Bold", Arial, Helvetica, Sans-Serif;
$font--poppins-semiBold: "Poppins SemiBold", Arial, Helvetica, Sans-Serif;
$font--togalite-bold: "Togalite Bold", Arial, Helvetica, Sans-Serif;