.gform_wrapper,
.wpcf7 {
	.gform_required_legend {
		display: none;
	}
	form {
		@include bp(lg) {
			display: flex;
			flex-wrap: nowrap;
			width: 100%;
			flex-direction: row;
		}
	}

	.gform_submission_error, .gfield_validation_message {
		color: $color-red-keyline;
		font-family: $font--poppins-medium;
		font-size: 16px;
	}

	.gfield_validation_message {
		margin-top: 10px;
		font-size: 12px;
	}

	.gfield_required {
		color: $color-black;
		//margin-left: 5px;
	}

	.gform_body,
	.wpcf7-form {
		@include bp(xs) {
			padding: 30px;
		}

		@include bp(lg) {
			padding: 11px 60px;
			flex-grow: 1;
		}

		h2 {
			margin-top: 0;
			font-size: 24px;
			border-bottom: 2px solid $color-keyline;
			padding-bottom: 10px;
			margin-bottom: 35px;

			@include bp(xs) {
				font-size: 30px;
			}
		}

		label {
			margin-bottom: 13px !important;
			display: block;
			font-size: 14px;

			.mandatory {
				color: $color-red;
			}
		}

		p {
			margin: 0;
		}

		fieldset {
			padding: 0;
			border: 0;
			border-radius: 0;

			legend {
				display: none;
			}
			.gchoice {
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
			}
			input {
				margin-right: 20px;
			}
			label {
				line-height: 2;
			}
		}

		input {
			&[type="text"],
			&[type="email"],
			&[type="file"],
			&[type="number"] {
				width: 100%;
				background-color: $color-white;
				border: 2px solid $color-gold-dark;
				border-radius: 0;
				padding: 14px;
				font-size: 12px;

				&.wpcf7-not-valid {
					border: 2px solid red;
				}

				&:active,
				&:focus {
					border: 2px solid $color-gold-dark;
					outline: none;
				}
			}
			&[type="checkbox"] {
				appearance: none;
				float: left;
				font-size: 12px;

				&:before {
					width: 29px;
					height: 29px;
					background-color: transparent;
					border: 2px solid $color-gold-dark;
					border-radius: 0;
					content: "";
					display: inline-block;
					position: relative;
					overflow: hidden;
					text-align: center;
					line-height: 19px;
					font-size: 24px;
				}

				&:checked {
					&:before {
						content: '■';
						color: $color-gold-dark;
					}
				}
			}
		}

		select {
			width: 100%;
			position: relative;
			z-index: 10;
			border: 2px solid $color-gold-dark;
			padding: 14px;
			appearance: none;
			background-image: url(img/arrow-down-black.svg);
			background-position: calc(100% - 15px) 50%;
			background-size: 16px 16px;
			background-repeat: no-repeat;
			font-size: 12px;
		}

		textarea {
			width: 100%;
			height: 150px;
			background-color: $color-white;
			border: 2px solid $color-gold-dark;
			padding: 14px;
			font-size: 12px;
		}

		.columns {
			> .col {
				@include bp(lg) {
					padding-bottom: 17px;
				}
			}

			+ h2 {
				@include bp(lg) {
					margin-top: 30px;
				}
			}
		}
		.wpcf7-checkbox {
			.wpcf7-list-item {
				margin: 0;

				label {
					line-height: 25px;
					display: flex;

					.wpcf7-list-item-label {
						padding-left: 5px;
					}
				}
			}
		}

		.wpcf7-response-output {
			margin: 0;
			padding: 15px;
			color: white;
			border: none;
			background-color: red;
		}

		.wpcf7-not-valid-tip {
			margin-top: 5px;
		}
	}
	.gform_footer {
		@include bp(lg) {
			padding: 40px 60px 40px 20px;
		}

		input {
			&[type="submit"] {
				@include buttonPrimary();

				border-radius: 0;
			}
		}
	}
	.gform_validation_container {
		display: none;
	}
	.gform_validation_errors {
		margin: 20px 0;
	}
}

.gform_confirmation_message {
	padding-bottom: 30px;
	color: $color-red-keyline;
	font-size: 16px;

	@include bp(lg) {
		padding-bottom: 40px;
		font-size: 20px;
	}
}