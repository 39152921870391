.content-boxes {
	padding-bottom: 70px;
	position: relative;
	z-index: 11;

	@include bp(lg) {
		padding-top: 135px;
		padding-bottom: 190px;
	}

	.container {
		position: relative;

		@include bp(lg) {
			max-width: 1265px;
		}
	}

	&--list {
		justify-content: center;
	}

	&--intro {
		position: relative;
		text-align: left;

		@include bp(lg) {
			padding-bottom: 50px;
		}
	}

	.columns--three {
		@include bp(lg) {
			margin-left: -35px;
			margin-right: -35px;
			align-items: stretch;
		}
		.col {
			@include bp(lg) {
				margin-bottom: 0px;
				padding: 0 35px;
				display: flex;
				flex-direction: column;
			}

			//.content-boxes--drip {
			//	display: none;
			//	visibility: hidden;
			//
			//	@include bp(lg) {
			//		display: block;
			//		text-align: right;
			//	}
			//
			//	svg {
			//		@include bp(lg) {
			//			margin-left: 40px;
			//		}
			//	}
			//}

			//&:last-child {
			//	.content-boxes--drip {
			//		@include bp(lg) {
			//			visibility: visible;
			//		}
			//	}
			//}
		}
	}

	&--content {
		background-color: $color-blue;
		padding: 17px 30px 30px 30px;
		text-align: center;

		@include bp(lg) {
			padding: 32px 30px 55px 30px;
			height: 100%;
			display: flex;
			flex-direction: column;
		}
	}

	h3 {
		font-size: 24px;

		@include bp(lg) {
			margin-bottom: 26px;
			font-size: 36px;
		}
	}

	p {
		font-size: 12px;

		@include bp(lg) {
			font-size: 16px;
			margin-bottom: 32px;
			margin-top: 0;
		}

		&.cta {
			margin-bottom: 0;

			@include bp(lg) {
				margin-top: auto;
				padding: 0 28px;
			}

			.btn {
				@include bp(lg) {
					padding: 0 30px;
				}
			}
		}
	}
}

.template-private-dining {
	.content-boxes {
		@include bp(lg) {
			padding-top: 75px;
		}
	}

	.content-boxes p:not(.cta) {
		@include bp(lg) {
			margin-bottom: 20px;
		}
	}

	.content-boxes--content {
		@include bp(lg) {
			width: 360px;
		}
	}
}