a {
	color: $color-gold-dark;

	&:hover,
	&:focus,
	&:active {
		color: $color__link-hover;
	}

	&:focus {
		outline: thin dotted;
	}

	&:hover,
	&:active {
		outline: 0;
	}
}

.social-container {
	a {
		width: 45px;
		height: 45px;
		border-radius: 50%;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $color-gold;
	}
}