.feature-smudge {
	font-family: $font--togalite-bold, serif;
	color: $color-white;
	letter-spacing: 1px;
	font-size: 16px;
	white-space: nowrap;
	background-position: center;
	background-repeat: no-repeat;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 100;
	margin-bottom: 10px;

	@include bp(lg) {
		font-size: 24px;
	}

	&.xl {
		font-size: 26px;

		@include bp(lg) {
			font-size: 40px;
		}
	}

	&.primary {
		width: 196px;
		height: 55px;
		//transform: rotate(180deg);

		@include bp(lg) {
			width: 329px;
			height: 93px;
		}

		&.extra-large {
			max-width: 330px;
			width: 100%;
			height: 73px;

			@include bp(lg) {
				height: 93px;
				max-width: 400px;
			}

			//svg {
			//	&:nth-child(1) {
			//		@include bp(lg) {
			//			right: -22px;
			//			left: auto;
			//		}
			//	}
			//
			//	&:nth-child(2) {
			//		@include bp(lg) {
			//			left: -45px;
			//		}
			//	}
			//}
		}

		&.xl {
			span {
				margin-top: 15px;
			}
		}
	}

	&.secondary {
		width: 235px;
		height: 71px;

		@include bp(lg) {
			width: 346px;
			height: 105px;
		}

		span {
			margin-left: -30px;

			@include bp(lg) {
				margin-left: -40px;
			}
		}
	}

	&.tertiary {
		max-width: 285px;
		width: 100%;
		height: 71px;

		span {
			margin-left: -70px;
		}

		//&--small {
		//	span {
		//		margin-left: -70px;
		//	}
		//}

		&.centered {
			svg {
				left: 45px;

				@include bp(lg) {
					left: 70px;
				}
			}

			span {
				margin-left: 15px;

				@include bp(lg) {
					margin-left: 0;
				}
			}
		}

		@include bp(lg) {
			max-width: 410px;
			width: 100%;
			height: 105px;

			span {
				margin-left: -125px;
			}

			&--small {
				span {
					margin-left: -70px;
				}

				&.centered {
					svg {
						left: 45px;
					}
				}
			}
		}

		&--small {
			max-width: 200px;
			width: 100%;
			height: 68px;
			position: relative;

			@include bp(lg) {
				max-width: 275px;
				height: 73px;
			}

			span {
				margin-left: -70px;
			}
		}
	}

	&.quarternary {
		width: 176px;
		height: 62px;

		@include bp(lg) {
			width: 264px;
			height: 93px;
		}

		span {
			margin-left: -15px;
		}
	}

	&.quinary {
		width: 219px;
		height: 62px;

		@include bp(lg) {
			width: 328px;
			height: 93px;
		}
	}

	&.colour--black {
		color: $color-black;
	}

	&.bg--red {
		svg {
			g {
				path {
					fill: $color-red;
				}
			}
		}
	}

	&.bg--green {
		svg {
			g {
				path {
					fill: $color-green;
				}
			}
		}

		&.quarternary {
			svg {
				path {
					fill: $color-green;
				}
			}
		}
	}

	&.bg--gold {
		svg {
			g {
				path {
					fill: $color-gold;
				}
			}
		}
	}

	&.bg--gold-dark {
		svg {
			g {
				path {
					fill: $color-gold-dark;
				}
			}
		}
	}

	&.bg--purple {
		svg {
			g {
				path {
					fill: $color-purple;
				}
			}
		}

		&.quarternary {
			svg {
				path {
					fill: $color-purple;
				}
			}
		}
	}

	&.centered {
		margin-left: auto;
		margin-right: auto;
	}

	svg {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		z-index: 5;
	}

	span {
		position: relative;
		z-index: 10;
	}
}