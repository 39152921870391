.video--embed {
	width: 100%;
	position: relative;
	overflow: hidden;
	max-width: 100%;
	height: 165px;
	margin-bottom: 20px;

	@include bp(sm) {
		height: 350px;
		margin-bottom: 30px;
	}

	@include bp(lg) {
		max-width: 860px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 48px;
		height: 386px;
	}

	iframe,
	object,
	embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.post-video {
	padding: 5px 20px;
	background-color: $color-white;
	position: relative;
	z-index: 10;

	@include bp(lg) {
		padding-top: 70px;
		max-width: 1050px;
		margin: auto;
	}

	.video--embed {
		@include bp(lg) {
			max-width: 760px;
		}
	}

	.video--duration {
		display: none;
		color: $color-black;

		@include bp(lg) {
			display: block;
			font-size: 16px;
			max-width: 760px;
			margin-top: -27px;
		}
	}
}