.booking-callout {
	padding-top: 50px;
	padding-bottom: 60px;
	position: relative;
	text-align: center;

	@include bp(lg) {
		padding-bottom: 130px;
	}

	&--content {
		&-inner {
			background-color: $color-blue;
			position: relative;
			z-index: 5;
			//margin-top: -45px;
			padding: 30px 20px;

			@include bp(lg) {
				margin: 0 auto;
				max-width: 930px;
				padding: 70px 40px 50px 40px;

				&:before {
					content: url('img/left-border-mid.svg');
					left: -32px;
					position: absolute;
					bottom: -31px;
				}

				&:after {
					content: url('img/left-border-mid.svg');
					position: absolute;
					top: -35px;
					right: -32px;
					transform: rotate(180deg);
				}
			}
		}

		h2 {
			padding-bottom: 20px;
			margin-bottom: 20px;
			position: relative;

			@include bp(lg) {
				text-transform: unset;
				font-size: 36px;
				letter-spacing: 2px;
				padding: 0;
			}
		}

		p {
			font-size: 11px;

			@include bp(lg) {
				font-size: 16px;
			}

			&:first-of-type {
				@include bp(lg) {
					padding-bottom: 37px;
					position: relative;
				}

				&:after {
					@include bp(lg) {
						content: "";
						width: 75px;
						height: 3px;
						background-color: $color-gold-dark;
						position: absolute;
						bottom: 0;
						left: 50%;
						transform: translateX(-50%);
					}
				}
			}

			.btn {
				margin-top: 18px;

				@include bp(lg) {
					margin-top: 31px;
					width: auto;
				}
			}
		}
	}
}

.template-single {
	.booking-callout {
		background-color: $color-white;
		position: relative;
		z-index: 11;

		@include bp(lg) {
			max-width: 1050px;
			margin: auto;
		}
	}
}