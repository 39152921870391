@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return $number / ($number * 0 + 1);
    }

    @return $number;
}

@function calcFluidFontSize($fMin, $fMax, $wMin, $wMax, $units: px) {
    $fMin: strip-unit($fMin);
    $fMax: strip-unit($fMax);
    $wMin: strip-unit($wMin);
    $wMax: strip-unit($wMax);

    $k: ($fMax - $fMin) / ($wMax - $wMin);
    $b: $fMin - $k * $wMin;

    $b: $b + $units;

    @return calc(#{$k} * 100vw + #{$b});
}