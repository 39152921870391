.availability {
	padding: 0;
	position: relative;
	z-index: 15;
	color: $color-black;
	display: none;

	@include bp(lg) {
		display: block;
		//padding-bottom: 150px;
	}

	&-illustration {
		position: absolute;
		right: 100%;
		top: 0;
		width: 375px;
		height: 100%;

		img {
			position: absolute;
			top: -85px;
			right: -39px;

			&:nth-child(2) {
				top: auto;
				bottom: -46px;
				right: -72px;
			}
		}

		&.right {
			width: 435px;
			right: auto;
			left: 100%;

			img {
				right: auto;
				left: -110px;
				top: -151px;

				&:nth-child(2) {
					top: auto;
					bottom: 0;
					left: -117px;
				}
			}
		}
	}

	&-inner {
		transform: translateY(-130px);
		margin: 0 auto;
		padding: 45px;
		max-width: 992px;
		background-color: $color-white;
		position: relative;
		display: flex;
		flex-wrap: wrap;
		-webkit-box-shadow: 0px 0px 70px #00000076;
		box-shadow: 0px 0px 70px #00000076;
	}

	&-content {
		padding: 0 45px 20px 45px;
		margin-bottom: 38px;
		text-align: center;
		position: relative;
		flex: 0 1 100%;

		h2 {
			margin: 0 0 20px 0;
		}

		&:after {
			background-color: $color-gold-dark;
			width: 78px;
			height: 4px;
			-webkit-transform: translateX(-50%);
			transform: translateX(-50%);
			position: absolute;
			left: 50%;
			bottom: 0;
			content: "";
			font-size: 0;
		}
	}

	&-advisories {
		.advisory {
			display: none;
			color: $color-gold-dark;

			&.open {
				display: block;
			}
		}
	}

	&-column {
		label {
			display: block;
			font-size: 14px;
			font-family: $font--poppins-medium;
			font-weight: 600;
			margin-bottom: 15px;
		}

		select {
			position: relative;
			width: 100%;
			border-radius: 0;
			z-index: 10;
			border: 2px solid $color-black;
			font-size: 16px;
			background-color: white;
			padding: 20px 13px;
			text-transform: uppercase;
			appearance: none;
			background-image: url(img/select-arrow-down.svg);
			background-repeat: no-repeat;
			background-position: calc(100% - 12px) center;
		}

		.datepicker {
			margin-top: 0;

			.date {
				height: 62px;
				position: relative;
				margin: 0;
				width: 100%;
				border-radius: 0;
				z-index: 10;
				border: 2px solid $color-black;
				font-size: 16px;
				background-color: white;
				display: flex;
				text-transform: uppercase;
				align-items: flex-start;
				justify-content: flex-start;

				input {
					padding: 20px 13px;
					border: 0;
					flex-grow: 1;
					border-radius: 0;
					background-color: transparent;
					outline: none;
				}

				button {
					width: 58px;
					height: 58px;
					padding: 0;
					border: 0;
					margin: 0;
					left: auto;
					top: 0;
					bottom: 0;
					border-radius: 0;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}

			.datepickerDialog {
				border: 2px solid $color-gold;
				border-radius: 0px;
				width: 100%;
				background-color: $color-white;
				color: $color-black;
				right: 0;

				.header {
					padding: 20px 17px;
					background-color: transparent;
				}

				table {
					padding-top: 0;
					margin: 0;
					padding-left: 15px;
					padding-right: 15px;
				}

				th {
					font-size: 12px;
					text-transform: uppercase;
					font-family: $font--poppins-medium;
					font-weight: 600;
				}

				td {
					width: 32px;
					height: 32px;
				}

				.dateButton {
					background-color: $color-gold;
					border: 0px;
					border-radius: 0;
					color: $color-black;
					cursor: pointer;
					font-size: 14px;
					transition: background-color 0.5s;

					&:hover {
						background-color: $color-white;
					}

					&.disabled {
						opacity: 0.5;
					}
				}

				.dialogButtonGroup {
					margin: 0;
					padding: 20px 17px;
					display: flex;
					justify-content: space-between;
				}

				.dialogButton {
					width: auto;
					background-color: $color-gold-dark;
					color: white;
					font-family: $font--chromate;
					margin: 0;
					border-radius: 0;
					text-transform: uppercase;
					padding: 15px 20px;
					flex: 1;
					text-align: center;
					border: 1px solid $color-black;
					transition: background-color 0.5s;
					cursor: pointer;

					&:hover {
						background-color: $color-gold;
					}

					//&:first-child {
					//	background-color: transparent;
					//}
				}

				.message {
					display: none;
				}
			}
		}

		&:nth-child(2) {
			flex: 0 1 24.75%;
			margin-right: 25px;
		}
		&:nth-child(3) {
			flex: 0 1 17.5%;
			margin-right: 25px;
		}
		&:nth-child(4) {
			flex: 0 1 16.5%;
			margin-right: 25px;
		}
		&:nth-child(5) {
			flex: 1;
		}
		&:nth-child(6) {
			flex: 0 1 21.75%;
		}
	}

	&-submit {
		padding: 45px 45px 0 45px;
		text-align: center;
		flex: 0 1 100%;

		input[type="submit"] {
			@include buttonPrimary();

			width: auto;
			margin: auto;
			min-height: 54px;
			border-radius: 15px;
			font-size: 16px;
			color: $color-white;
			padding: 0 15px;
			text-align: center;
			display: block;

			@include bp(lg) {
				padding: 0 34px;
				line-height: 54px;
			}
		}
	}

	//&-drips {
	//	display: flex;
	//	align-items: flex-start;
	//	justify-content: space-between;
	//	max-width: 992px;
	//	margin: auto;
	//}

	&--book {
		display: block;
		background: transparent;
		padding-bottom: 40px;
		margin-bottom: -60px;

		@include bp(lg) {
			margin-bottom: 0;
		}

		.availability-inner {
			margin-top: 0;
			padding: 32px 20px 20px 20px;
			justify-content: space-between;

			@include bp(lg) {
				padding: 52px 45px 45px 45px;
			}
		}
		.availability-content {
			padding: 0 0 18px 0;
			margin: 0 auto 30px auto;
			width: 100%;
			max-width: 780px;

			//@include bp(lg) {
			//	padding: 0 0 36px 0;
			//	margin: 0 0 55px 0;
			//}

			label {
				width: 12px;
			}

			h2 {
				font-size: 16px;
				line-height: 1.35;

				@include bp(lg) {
					font-size: 24px;
				}
			}
		}
		.availability-illustration {
			display: none;

			@include bp(lg) {
				display: block;
			}

			img {
				@include bp(lg) {
					top: 20px;
				}
			}

			&.right {
				img {
					@include bp(lg) {
						top: 48px;
						left: -90px;
					}
				}
			}
		}
		.availability-column {
			flex: 0 1 100%;
			width: 100%;
			margin: 0 0 20px 0;

			label {
				font-size: 12px;
				margin-bottom: 10px;

				@include bp(lg) {
					font-size: 14px;
				}
			}

			select {
				font-size: 12px;
				padding: 19px 13px;

				@include bp(lg) {
					font-size: 16px;
					padding: 20px 13px;
				}
			}

			&.half {
				flex: 0 1 47%;
				width: 47%;
			}

			&:nth-child(2) {
				@include bp(lg) {
					flex: 0 1 24.75%;
					margin-right: 25px;
				}
			}
			&:nth-child(3) {
				@include bp(lg) {
					flex: 0 1 17.5%;
					margin-right: 25px;
				}
			}
			&:nth-child(4) {
				@include bp(lg) {
					flex: 0 1 16.5%;
					margin-right: 25px;
				}
			}
			&:nth-child(5) {
				@include bp(lg) {
					flex: 1;
				}
			}
			&:nth-child(6) {
				@include bp(lg) {
					flex: 0 1 21.75%;
				}
			}
		}
		.availability-drips {
			svg {
				&:first-child {
					display: none;

					@include bp(lg) {
						display: block;
					}
				}
				&:last-child {
					margin-left: auto;
				}
			}
		}
		.availability-submit {
			padding: 10px 0 20px 0;

			@include bp(lg) {
				padding: 25px 0 0 0;
			}

			input[type="submit"] {
				border-radius: 0px;
				width: 100%;

				@include bp(lg) {
					width: auto;
				}
			}
		}
	}
}